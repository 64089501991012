/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckCircle, ControlPoint } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, FormHelperText, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import ModalLayout from "src/components/ModalLayout";
import Page from "src/components/Page";
import QuestionComponent from "src/components/ui/Question";
import WaitingBox from "src/components/WaitingBox";
import useSettings from "src/hooks/useSettings";
import api from "src/services/api";
import axiosInstance from "src/utils/axios";
import axiosInstanceV2 from "src/utils/axiosV2";
import { useMutationCustom, useQueryCustom } from "src/utils/reactQueryHooks";
import HamrahSection from "./component/hamrah";
import persianToEnglishNumber from "src/utils/persianToEnglishNumber";
import { fDateForApi } from "src/utils/formatTime";
import FormSchema from "./validation";
// import FormInputImage from "../../Section/InputsCore/Image";
import { newDate } from "src/utils/dating";
import FormInputImage from "src/pages/RegistrantForms/Section/InputsCore/Image";
import { PATH_DASHBOARD } from '../../../../../../../routes/paths';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { groupBy } from "lodash";

const DutyRegisteryForm = ({
    travelId,
    clientId,
    data,
    refetch
}) => {

    const { themeStretch } = useSettings();

    const ModalLayoutQuestionInputRef = useRef();
    const ModalLayoutSuccessRef = useRef();

    const admin = useSelector((store) => store.admin.data);
    const [SearchParams] = useSearchParams();
    const navigate = useNavigate();

    const formInputId = {
        name: {
            name: "name",
            label: "نام",
            require: true,
            id: 160
        },
        last_name: {
            name: "last_name",
            label: "نام خانوادگی",
            require: true,
            id: 161
        },
        shenasname: {
            name: "shenasname",
            label: "شماره شناسنامه",
            require: true,
            id: 162
        },
        codeMeli: {
            name: "codeMeli",
            label: "کدملی",
            require: true,
            id: 163
        },
        father_name: {
            name: "father_name",
            label: "نام پدر",
            require: true,
            id: 164
        },
        birthDate: {
            name: "birthDate",
            label: "تاریخ تولد",
            require: true,
            id: 165,
            type: "DATE"
        },
        phone: {
            name: "phone",
            label: "تلفن همراه",
            require: true,
            id: 166,
        },
        address: {
            name: "address",
            label: "آدرس",
            require: true,
            id: 167,
        },
        postalCode: {
            name: "postalCode",
            label: "کدپستی",
            require: true,
            id: 168,
        },
        homePhone: {
            name: "homePhone",
            label: "تلفن ثابت(به همراه کد)",
            require: true,
            id: 141,
        },
        sodorShenasnameh: {
            name: "sodorShenasnameh",
            label: "محل صدور شناسنامه",
            require: true,
            id: 157,
        },
        job: {
            name: "job",
            label: "شاغل در",
            require: true,
            id: 158,
        },
        taahodName: {
            name: "taahodName",
            label: "فرم تعهد نامه",
            require: true,
            type: "IMAGE",
            id: 159,
        },
        scanCartMeli: {
            name: "scanCartMeli",
            label: "اسکن شناسنامه یا کارت ملی ضامن",
            require: true,
            type: "IMAGE",
            id: 171,
        },
    }

    const formInputIdArr = Object.values(formInputId)

    const defaultValues = useMemo(
        () => ({
            name: '',
            last_name: "",
            shenasname: "",
            codeMeli: "",
            father_name: "",
            birthDate: "",
            phone: "",
            address: "",
            postalCode: "",
            homePhone: "",
            sodorShenasnameh: "",
            job: "",
            taahodName: null,
            scanCartMeli: null,

            // Remove In onSubmit
            gender: "",
            nezam_vazifeh: "",
            companionCount: 0,
            companions: [],
        }),

        []
    );

    const queryParams = useParams();
    const { id, travel } = queryParams;
    const [beforeRegistrant, setBeforeRegistrant] = useState();

    const [HideForWemen, setHideForWemen] = useState(false)
    const [HideForMainClient, setHideForMainClient] = useState(false)
    const [CompnionArrState, setCompnionArrState] = useState([])

    const [companionSectionId, setCompanionSectionId] = useState();

    const [FirstFormData, setFirstFormData] = useState({})

    const [ShowZamenForm, setShowZamenForm] = useState(false)
    const [FormDataApi, setFormDataApi] = useState({})
    const [HelpCompanionData, setHelpCompanionData] = useState({})
    const [ShowZamenFormHamrah, setShowZamenFormHamrah] = useState(false)


    const handleQuestionModal = (params) => ModalLayoutQuestionInputRef.current.show((p) => !p, params);
    const handleSuccessModal = (params) => ModalLayoutSuccessRef.current?.show((p) => !p, params, { disableBackDrop: true });

    const methods = useForm({
        resolver: yupResolver(FormSchema),
        // mode: 'onChange',
        mode: 'all',
        shouldUnregister: false,
        defaultValues: defaultValues
    });

    const {
        reset,
        watch,
        control,
        setValue,
        setError,
        handleSubmit,
        getValues,
        formState: { errors, isSubmitting },
    } = methods;

    const isError = Object.keys(errors)?.length;


    // ----------------------
    const creating = (params) => axiosInstance.post(api.public.travelRegister.base, params);
    // const updating = (params) => axiosInstance.post(`${api.public.travelRegister.base}/${id}`, params);
    const updating = (params) => axiosInstance.post(`${api.travelRegister.base}/${travelId || data?.form?.travel?.id}`, params);
    const getById = () => axiosInstanceV2.get(`${api.travelRegister.register.base}/${queryParams?.["*"]}`);
    // const getFormById = () => axiosInstance.get(`${api.form.base}/${data?.form?.id}`);
    // ----------------------

    const handleDownloadZamenWord = () => {
        axiosInstance
            .get(`${api.client.word}?travel_id=${travelId}`, {
                responseType: 'blob'
            })
            .then(({ data }) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', 'تعهدنامه.docx');

                document.body.appendChild(link);
                link.click();

                link.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.log(err);
                console.log({ wordErr: err.response, err, message: err.response.data?.message })
                toast.error(err.response?.message)
                if (err.response && err.response.status === 404) {
                    // Convert the error blob to text to read the error message
                    const reader = new FileReader();
                    reader.onload = () => {
                        const errorMessage = JSON.parse(reader.result)?.message;
                        toast.error(errorMessage)
                    };
                    reader.onerror = () => {
                        console.log("Failed to read error message");
                    };
                    reader.readAsText(err.response.data); // Reads the error blob as text
                } else {
                    console.log("Error:", err.message); // General error message
                }

            });
    };

    const onSuccessMutating = () => {
        handleSuccessModal();
    };

    const { isLoading, mutate } = useMutationCustom({
        url: beforeRegistrant ? updating : creating,
        name: `${api.public.travelRegister.base}_add_first`,
        // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
        onSuccess: onSuccessMutating,
        //  onError: onErrorMutating,
    });

    const handleDownloadZamenWordBtn = async () => {
        // await onSubmit();
        handleDownloadZamenWord();
    }

    const onSubmit = () => {
        handleQuestionModal();

        const values = getValues();

        console.log({ values })

        const formData = new FormData();

        formData.append('form_id', data?.form?.id);
        formData.append('travel_id', travelId);
        formData.append('client_id', FirstFormData?.clients?.[0]?.id);
        if (beforeRegistrant) formData.append('_method', 'put');


        Object.entries(values).map(([key, value], index) => {

            if (key === "companionCount" || key === "companions") {
                return
            }

            if (
                value === null ||
                value === undefined ||
                value === "undefined" ||
                !formInputId[key]
            ) return

            let newVal = value;

            try {
                if (newVal instanceof Date) {
                    console.log('* * * onSubmit The value is a Date object.');
                    newVal = fDateForApi(newVal);
                }
            } catch (error) {
                console.log({ error });
            }

            if (formInputId[key].type === "IMAGE") {
                if (typeof newVal === 'string') return;
            }

            const val = persianToEnglishNumber(newVal);

            formData.append(`array[${index}][input_id]`, formInputId[key].id);
            formData.append(`array[${index}][value]`, val);
        })

        for (let i = 0; i < values?.companions?.length; i += 1) {
            const element = values?.companions[i];
            formData.append(`companions[${i}][0][client_id]`, HelpCompanionData[i]?.id);
            Object.entries(element).map(([key, value], index) => {

                if (key === "companionCount" || key === "companions") {
                    return
                }

                if (
                    value === null ||
                    value === undefined ||
                    value === "undefined" ||
                    !formInputId[key]
                ) return

                let newVal = value;

                try {
                    if (newVal instanceof Date) {
                        console.log('* * * onSubmit The value is a Date object.');
                        newVal = fDateForApi(newVal);
                    }
                } catch (error) {
                    console.log({ error });
                }

                if (formInputId[key].type === "IMAGE") {
                    if (typeof newVal === 'string') return;
                }

                const val = persianToEnglishNumber(newVal);

                formData.append(`companions[${i}][${index + 1}][input_id]`, formInputId[key].id);
                formData.append(`companions[${i}][${index + 1}][value]`, val);
            })
        }

        // for (const keys of formData.keys()) {
        //     console.log('* * * onSubmit : ', { keys, value: formData.get(keys) });
        // }


        mutate(formData)

    }

    function findDuplicateCompanions(arr) {
        const result = [];
        const companionMap = new Map();

        arr.forEach(item => {
            if (!companionMap.has(item.companion_number)) {
                companionMap.set(item.companion_number, item);
            } else {
                const existingItem = companionMap.get(item.companion_number);
                if (existingItem.id !== item.id) {
                    result.push(existingItem.id > item.id ? existingItem : item);
                }
            }
        });

        return result;
    }


    const onSuccess = (resData) => {
        console.log('* * * onSuccess : ', { resData, FirstFormData });

        const formData = resData?.form;

        let companionCount = resData?.members || 0;

        const companionFill = [];

        const compainonHelpData = [];

        let formInputValue = defaultValues;

        const userGenderStatus = FirstFormData?.clients?.[0]?.gender;
        const userDutyStatus = FirstFormData?.clients?.[0]?.nezam_vazife_status;

        for (let i = 1; i < FirstFormData?.clients?.length; i += 1) {
            const element = FirstFormData?.clients[i];
            if (element?.gender === "مونث") {
                // companionFill = companionFill.splice(i - 1, 1);
            } else if (element?.gender === "مذکر" && element.nezam_vazife_status !== "معافیت تحصیلی") {
                // companionFill = companionFill.splice(i - 1, 1);
            } else {
                if (companionFill[i]) {
                    companionFill[i].sectionName = i == 1 ? "همسر" : `فرزند ${i - 1}`;
                    companionFill[i].id = element?.id;
                    compainonHelpData[i].client = element
                    compainonHelpData[i].sectionName = i == 1 ? "همسر" : `فرزند ${i - 1}`;
                    compainonHelpData[i].id = element?.id

                } else {
                    const helpObj = {
                        sectionName: i == 1 ? "همسر" : `فرزند ${i - 1}`,
                        id: element?.id
                    }
                    companionFill?.push(helpObj)
                    compainonHelpData.push({
                        ...helpObj,
                        client: element
                    })
                }
            }
        }
        companionCount = companionFill?.length;
        console.log({ companionFill, companionCount })

        for (let i = 0; i < formData?.sections?.length; i += 1) {
            const section = formData?.sections[i];

            for (let j = 0; j < section?.inputs?.length; j += 1) {
                const input = section?.inputs[j];

                if (section?.label === "اطلاعات همراه" && companionCount > 0) {
                    // const companionItem = defaultValues;

                    if (input?.client_inputs?.[0]?.value) {
                        const findInput = formInputIdArr.find(item => item.id === input?.id);
                        input.client_inputs = input.client_inputs?.sort(
                            (a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number)
                        );

                        // input.client_inputs = findDuplicateCompanions(input.client_inputs)

                        // console.log({ companionFill: findDuplicateCompanions(input.client_inputs) })

                        for (let k = 0; k < input.client_inputs?.length; k++) {
                            const element = input.client_inputs[k];
                            if (companionFill[element?.companion_number || 0]) {
                                // console.log({companionFill : companionFill[k]?.id , element , len : input.client_inputs?.length})
                                // companionFill[element?.companion_number || 0][findInput.name] = element?.value;
                                if (input?.type === "DATE") {
                                    companionFill[element?.companion_number || 0][findInput.name] = newDate(element?.value);
                                } else {
                                    companionFill[element?.companion_number || 0][findInput.name] = element?.value;
                                }
                                compainonHelpData[element?.companion_number || 0][findInput.name] = {
                                    ...element,
                                    need_confirm: input?.need_confirm,
                                    // client_inputs: input?.client_inputs,
                                    client_inputs: [element],
                                };
                                // compainonHelpData[element?.companion_number || 0].need_confirm = input?.need_confirm;
                                if (input?.client_inputs?.length > 0) {
                                    input.client_inputs[k].need_confirm = input?.need_confirm
                                }
                                compainonHelpData[element?.companion_number || 0].client_inputs = [element];
                            }
                            // else {
                            //     companionFill.push({
                            //         [findInput.name]: element?.value
                            //     })
                            //     compainonHelpData.push({
                            //         [findInput.name]: element
                            //     })
                            // }
                        }
                    }


                } else {
                    const findInput = formInputIdArr.find(item => item.id === input?.id);
                    if (findInput && input?.client_inputs?.[0]?.value) {
                        setBeforeRegistrant(true);
                        if (input?.type === "DATE") {
                            formInputValue[findInput.name] = newDate(input?.client_inputs?.[0]?.value);
                        } else {
                            formInputValue[findInput.name] = input?.client_inputs?.[0]?.value;
                        }
                    }
                }
            }
        }

        if (userGenderStatus === "مونث" && companionFill?.length === 0) {
            setHideForWemen(true)
        }

        if (
            (userGenderStatus === "مونث" && companionFill?.length > 0) ||
            (userDutyStatus !== "معافیت تحصیلی" && companionFill?.length > 0)
        ) {
            setHideForMainClient(true)
            formInputValue = {
                companions: formInputValue.companions
            }
        }

        if (userGenderStatus === "مونث" && companionFill?.length > 0) {
            setHideForMainClient(true)
            formInputValue = {
                companions: formInputValue.companions
            }
        }

        if (userGenderStatus === "مونث" && companionFill?.length === 0) {
            setHideForWemen(true)
        } else if (userDutyStatus !== "معافیت تحصیلی" && userGenderStatus === "مذکر" && companionFill?.length === 0) {
            setHideForWemen(true)
        } else {
            setHideForWemen(false);
        }


        formInputValue.companions = companionFill;

        formInputValue.gender = (userGenderStatus || "")?.trim();
        formInputValue.nezam_vazifeh = (userDutyStatus || "")?.trim();



        console.log({
            companionFill,
            formInputValue,
            compainonHelpData
        })

        setCompnionArrState(companionFill);
        setHelpCompanionData(compainonHelpData);
        setFormDataApi(formData)

        reset(formInputValue);

    };

    const handleSuccessFirstForm = (resData) => {
        setFirstFormData(resData?.data);
        setValue("gender", resData?.data?.clients?.[0]?.gender)
        setValue("nezam_vazifeh", resData?.data?.clients?.[0]?.nezam_vazife_status)
    }

    const firstFormOfTravel = useQueryCustom({
        url: getById,
        name: `${api.public.travelRegister.base}_getById`,
        params: { id: id },
        // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
        onSuccess: handleSuccessFirstForm,
        staleTime: 0, // Data becomes stale immediately
        cacheTime: 0,
        enabled: !!id
        //  onError: onErrorMutating,
    });

    // const resQuery = useQueryCustom({
    //     name: `${api?.form?.base}_get_${id}_${travelId}`,
    //     url: getFormById,
    //     params: { id },
    //     staleTime: 0, // Data becomes stale immediately
    //     cacheTime: 0,
    //     onSuccess: onSuccess,
    //     enabled: !!firstFormOfTravel.data, // Enable this query when firstFormOfTravel has data
    // });


    console.log({ data })

    useEffect(() => {
        if (!data) return;
        onSuccess(data);
        // firstFormOfTravel.refetch();
    }, [data, FirstFormData]);


    return (
        <>
            {(firstFormOfTravel.isLoading) ? <WaitingBox /> :
                <>
                    <Page
                        sx={{
                            py: 3,
                        }}
                        title={"اطلاعات نظام وظیفه"}
                    >
                        <ModalLayout ref={ModalLayoutQuestionInputRef}>
                            <QuestionComponent
                                {...{
                                    title: beforeRegistrant ? 'آیا از تغییر اطلاعات مطمئن هستید؟' : 'آیا از تایید ثبت نام مطمئن هستید؟',
                                    description: "پس از تایید اطلاعات فرم ضمانت خروج از کشور را پرینت گرفته، پس از امضاء و اثر انگشت مجددا بارگزاری نمایید.",
                                    button: {
                                        confirm: {
                                            label: 'question.yesProcess',
                                            onClick: (_, data) => onSubmit(data),
                                        },
                                        reject: {
                                            label: 'question.no',
                                            onClick: () => handleQuestionModal(),
                                        },
                                    },
                                }}
                            // onClose={() => handleQuestionModal()}
                            />
                        </ModalLayout>

                        <ModalLayout ref={ModalLayoutSuccessRef}
                            onClosed={refetch}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    border: '1px solid #ccc',
                                    borderRadius: 1,
                                    backgroundColor: 'common.white',
                                }}
                            >
                                {/* <Box sx={{
                            "svg":{
                                width:"80px",
                                height:"80px",
                                color:"success.main"
                            }
                        }}>
                            <CheckCircle />
                        </Box> */}
                                <Box
                                    sx={{
                                        mt: '-20px',
                                        svg: {
                                            width: '65px',
                                            height: '65px',
                                            color: 'success.main',
                                            backgroundColor: 'common.white',
                                            borderRadius: '100%',
                                        },
                                    }}
                                >
                                    <CheckCircle />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        p: 3,
                                        gap: 2,
                                        position: 'relative',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            color: 'success.main',
                                        }}
                                    >
                                        {beforeRegistrant
                                            ? 'تغییر اطلاعات شما با موفقیت انجام شد.'
                                            : 'اطلاعات شما با موفقیت ثبت شد'}
                                    </Typography>

                                    {/* {true ? <Typography variant="h6">{'منتظر قرعه کشی باشید.'}</Typography> : ''} */}

                                    <Button
                                        color="success"
                                        variant="contained"
                                        onClick={async () => {
                                            handleSuccessModal();
                                            navigate(PATH_DASHBOARD.visitorManagement.root("omre", SearchParams.get("travel")) + "/registrations")
                                            // completed?.();
                                            // refetch?.();
                                        }}
                                    >
                                        {'بستن'}
                                    </Button>
                                </Box>
                            </Box>
                        </ModalLayout>

                        <Container maxWidth={themeStretch ? false : 'lg'}>
                            {/* <HeaderBreadcrumbs heading={"اطلاعات نظام وظیفه"}>
                                <Typography>{data?.form?.travel?.name}</Typography>
                            </HeaderBreadcrumbs> */}

                            {(HideForWemen) ?
                                <>
                                    <Typography>شما نیازی به تکمیل این فرم ندارید.</Typography>
                                </>
                                :
                                <>
                                    <FormProvider methods={methods} onSubmit={handleSubmit(handleQuestionModal)}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 4,
                                            }}
                                        >

                                            {!HideForMainClient &&
                                                <>
                                                    {(!beforeRegistrant || ShowZamenForm) &&
                                                        <>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: "center",
                                                                    p: 2,
                                                                    backgroundColor: 'grey.main',
                                                                    borderRadius: '8px',
                                                                }}
                                                            >
                                                                <Typography>مشخصات ضامن</Typography>
                                                                {beforeRegistrant &&
                                                                    <Button variant="outlined" onClick={() => setShowZamenForm(!ShowZamenForm)}>
                                                                        {ShowZamenForm ? "فرم تعهدنامه" : "نمایش و ویرایش اطلاعات ضامن"}
                                                                    </Button>
                                                                }

                                                            </Box>
                                                        </>
                                                    }
                                                    <Grid sx={{}} container spacing={3}>

                                                        {(!beforeRegistrant || ShowZamenForm) &&
                                                            <>
                                                                <Grid item xs={12} md={6}>
                                                                    <RHFTextField
                                                                        name={"name"}
                                                                        label={"نام"}
                                                                        required
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <RHFTextField
                                                                        name={"last_name"}
                                                                        label={"نام خانوادگی"}
                                                                        required
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <RHFTextField
                                                                        name={"shenasname"}
                                                                        label={"شماره شناسنامه"}
                                                                        required
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <RHFTextField
                                                                        name={"codeMeli"}
                                                                        label={"کدملی"}
                                                                        required
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <RHFTextField
                                                                        name={"father_name"}
                                                                        label={"نام پدر"}
                                                                        required
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <RHFDatePicker
                                                                        name={"birthDate"}
                                                                        label={"تاریخ تولد"}
                                                                        pickerProps={{
                                                                            maxDate: new Date(),
                                                                        }}
                                                                        required
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <RHFTextField
                                                                        name={"phone"}
                                                                        label={"تلفن همراه"}
                                                                        required
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={12}>
                                                                    <RHFTextField
                                                                        name={"address"}
                                                                        label={"آدرس"}
                                                                        required
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <RHFTextField
                                                                        name={"postalCode"}
                                                                        label={"کدپستی"}
                                                                        required
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <RHFTextField
                                                                        name={"homePhone"}
                                                                        label={"تلفن ثابت(به همراه کد)"}
                                                                        required
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <RHFTextField
                                                                        name={"sodorShenasnameh"}
                                                                        label={"محل صدور شناسنامه"}
                                                                        required
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <RHFTextField
                                                                        name={"job"}
                                                                        label={"شاغل در"}
                                                                        required
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={12}>
                                                                    <FormInputImage
                                                                        name={"scanCartMeli"}

                                                                        data={{
                                                                            need_confirm: FormDataApi?.sections?.find(item => item?.label === "مشخصات ضامن")?.inputs?.find(item => item?.label === "اسکن شناسنامه یا کارت ملی ضامن")?.need_confirm,
                                                                            client_inputs: FormDataApi?.sections?.find(item => item?.label === "مشخصات ضامن")?.inputs?.find(item => item?.label === "اسکن شناسنامه یا کارت ملی ضامن")?.client_inputs,
                                                                            ...FormDataApi?.sections?.find(item => item?.label === "مشخصات ضامن")?.inputs?.find(item => item?.label === "اسکن شناسنامه یا کارت ملی ضامن")?.client_inputs?.[0],
                                                                            label: "اسکن شناسنامه یا کارت ملی ضامن",
                                                                            client_id: FirstFormData?.clients?.[0]?.id,
                                                                            type: "IMAGE"
                                                                        }}

                                                                        index={0}
                                                                        disabled
                                                                        useV1
                                                                        isHistory
                                                                        description={`فایل را بکشید و رها کنید یا روی دکمه انتخاب فایل کلیک کنید.
                                                                            حداکثر حجم قابل قبول برای هر عکس: 5 مگابایت
                                                                            فرمت های قابل قبول: gif، JPG، PNG`}



                                                                    // accept={"*"}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        }


                                                        {(beforeRegistrant && !ShowZamenForm) &&
                                                            <>
                                                                <Grid item xs={12} md={12}>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            alignItems: "center",
                                                                            p: 2,
                                                                            backgroundColor: 'grey.main',
                                                                            borderRadius: '8px',
                                                                        }}
                                                                    >
                                                                        <Typography>ارسال فرم تعهدنامه</Typography>
                                                                        {beforeRegistrant &&
                                                                            <Button variant="outlined" onClick={() => setShowZamenForm(!ShowZamenForm)}>
                                                                                {ShowZamenForm ? "فرم تعهدنامه" : "نمایش و ویرایش اطلاعات ضامن"}
                                                                            </Button>
                                                                        }

                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12} md={12}>
                                                                    <FormInputImage
                                                                        name={"taahodName"}

                                                                        data={{
                                                                            need_confirm: FormDataApi?.sections?.find(item => item?.label === "ارسال فرم تعهد نامه")?.inputs[0]?.need_confirm,
                                                                            client_inputs: FormDataApi?.sections?.find(item => item?.label === "ارسال فرم تعهد نامه")?.inputs[0]?.client_inputs,
                                                                            old_client_inputs: FormDataApi?.sections?.find(item => item?.label === "ارسال فرم تعهد نامه")?.inputs[0]?.old_client_inputs,
                                                                            ...FormDataApi?.sections?.find(item => item?.label === "ارسال فرم تعهد نامه")?.inputs[0]?.client_inputs?.[0],
                                                                            label: "فرم تعهد نامه",
                                                                            client_id: FirstFormData?.clients?.[0]?.id,
                                                                            type: "IMAGE"
                                                                        }}

                                                                        index={0}
                                                                        disabled
                                                                        isHistory
                                                                        useV1
                                                                        description={`فایل را بکشید و رها کنید یا روی دکمه انتخاب فایل کلیک کنید.
                                                                            حداکثر حجم قابل قبول برای هر عکس: 5 مگابایت
                                                                            فرمت های قابل قبول: gif، JPG، PNG`}


                                                                    // accept={"*"}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        }


                                                    </Grid>
                                                </>
                                            }



                                            {(
                                                CompnionArrState?.length > 0
                                            ) &&
                                                <>

                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: 4,
                                                            mt: 3
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                p: 2,
                                                                backgroundColor: 'grey.main',
                                                                borderRadius: '8px',
                                                            }}
                                                        >
                                                            <Typography>اطلاعات ضامن همراهان</Typography>

                                                            {!!HelpCompanionData[0]?.name &&
                                                                <Button variant="outlined" onClick={() => setShowZamenFormHamrah(!ShowZamenFormHamrah)}>
                                                                    {ShowZamenFormHamrah ? ("فرم تعهدنامه همراه") : ("نمایش و ویرایش اطلاعات ضامن همراه")}
                                                                </Button>
                                                            }

                                                        </Box>
                                                        {Array(parseInt(CompnionArrState?.length, 10)).fill("").map((_, index) =>
                                                            <>
                                                                <HamrahSection index={index} key={`hamrah-${index}`} getValues={getValues} HelpCompanionData={HelpCompanionData} ShowZamenFormHamrah={ShowZamenFormHamrah} beforRegister={!!HelpCompanionData[0]?.name} />
                                                            </>
                                                        )}


                                                    </Box>

                                                </>
                                            }
                                        </Box>

                                        {
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    gap: 1,
                                                    mt: 5,
                                                }}
                                            >

                                                <LoadingButton loading={isLoading} type="submit" variant="contained" color={'success'}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: 2,
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Typography>{beforeRegistrant ? 'ویرایش اطلاعات' : 'ثبت اطلاعات'}</Typography>
                                                        <ControlPoint />
                                                    </Box>
                                                </LoadingButton>

                                            </Box>
                                        }
                                        {isError ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'center',
                                                    mt: 1,
                                                }}
                                            >
                                                <FormHelperText error>{`لطفا تمام فیلد ها را با دقت پر نمایید`}</FormHelperText>
                                            </Box>
                                        ) : (
                                            ''
                                        )}
                                    </FormProvider>
                                </>
                            }

                        </Container>
                    </Page>
                </>
            }
        </>
    );
}

export default DutyRegisteryForm;