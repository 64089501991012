import { Box, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import persianToEnglishNumber from 'src/utils/persianToEnglishNumber';
import { useTranslation } from 'react-i18next';
import RoleSelector from 'src/pages/role/selector';
import RHFTextField from '../../../../../components/hook-form/RHFTextField';
import FormProvider from '../../../../../components/hook-form/FormProvider';
import validation from './validation';
import Enum from '../../../enum';
import { useMutationCustom, useQueryCustom } from '../../../../../utils/reactQueryHooks';
import axiosInstance from '../../../../../utils/axios';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import WaitingBox from '../../../../../components/WaitingBox/index';
import RHFTextareaField from '../../../../../components/hook-form/RHFTextareaField';
import RHFSelector from '../../../../../components/hook-form/RHFSelector';
import { NATIONAL_CODE_REGEX } from 'src/config';

const AddToCaravanWithCodeMeli = ({ onClose }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const paramId = queryParams?.id;

  const navigate = useNavigate();

  const backUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}/show/${queryParams.id}/${Enum.enumTab.object.client.value
    }`;
  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(Enum?.api?.clientWithCodeMelli, params);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    navigate(backUrl);
  };

  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${queryParams.id}`);

  const caravanById = useQueryCustom({
    name: `get_by_Id_${Enum?.api?.base}_${queryParams.travel}_${queryParams.id}`,
    url: getById,
    enabled: !!queryParams.id,
  });

  const caravan = caravanById?.data?.data;
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const { isLoading, mutate } = useMutationCustom({
    url: creating,
    name: `${Enum?.api?.base}_add_code_meli`,
    invalidQuery: `${Enum?.api?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  // ---------------------------------------
  // const onSuccess = (resData) => {
  //   console.log('* * * onSuccess', { resData });
  //   const resetData = {
  //     [validation.fieldNames.code_melli]: resData?.data?.[validation.fieldNames.code_melli] || '',
  //     [validation.fieldNames.message]: resData?.data?.[validation.fieldNames.message] || '',
  //     // [validation.fieldNames.social]: socialSelector.convertor.object(resData?.data?.social),
  //   };
  //   console.log('* * * onSuccess', { resetData });
  //   reset(resetData);
  // };
  // const dataById = useQueryCustom({
  //   name: `getById_${Enum?.api?.social}_${paramId}`,
  //   url: getById,
  //   onSuccess: onSuccess,
  //   enabled: !!paramId,
  // });
  const mobileRegex = /^(?:\+98|0)?9\d{9}$/;
  const watchStartDate = watch(validation.fieldNames.startDate);
  // console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();
    values[validation.fieldNames.code_melli] = persianToEnglishNumber(values[validation.fieldNames.code_melli]?.trim());
    const splitPhones = values[validation.fieldNames.code_melli]?.split('\n');
    // console.log('* * * onSubmit : ', { values, splitPhones });
    const inValidPhones = [];
    let isValid = true;
    splitPhones?.forEach((x) => {
      if (x?.trim()) {
        const valid = NATIONAL_CODE_REGEX.test(x);
        isValid = isValid && valid;
        if (!valid) inValidPhones.push(x);
      }
    });
    // console.log('* * * onSubmit : ', { isValid, inValidPhones });

    if (!isValid) {
      return setError(
        validation.fieldNames.code_melli,
        { message: `${inValidPhones?.join(' - ')} کدملی های نادرست ` },
        { shouldFocus: true }
      );
    }
    const reqData = {
      // [validation.fieldNames.message]: values[validation.fieldNames.message],
      [validation.fieldNames.code_melli]: splitPhones,
      team_id: queryParams.id,
    };
    // const validation
    console.log('* * * onSubmit : ', { reqData, values, splitPhones, isValid, inValidPhones });
    mutate(reqData);
  };
  console.log({ queryParams });

  return (
    <Box
      sx={{
        width: '100%',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      {/* <HeaderBreadcrumbs
        heading={`اضافه کردن با کدملی`}
        // back={backUrl}
        // links={[
        //   { name: 'داشبورد', href: Enum.routes.root(queryParams.type, queryParams.travel) },
        //   { name: title, href: Enum.routes.list },
        //   { name: 'لیست' },
        // ]}
      /> */}
      <HeaderBreadcrumbs
        back={`${backUrl}`}
        heading={`کاروان بندی با کدملی ${caravan?.name ? `(${caravan?.name})` : ""}`}
      />
      {/* </HeaderBreadcrumbs> */}
      {/* <Box
        sx={{
          textAlign: 'center',
          mb: '30px',
        }}
        onClick={() => navigate(backUrl)}
      >
        <Typography variant="h5">{'شما در حال ایجاد یک دوره جدید هستید!'}</Typography>
      </Box> */}




      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  fontSize: '13px',
                }}
              >
                ظرفیت کاروان :
              </Typography>
              <Typography>{caravan?.capacity}</Typography>
            </Grid> */}
            {/* <Grid item xs={12} md={12}>
              <RHFTextareaField name={validation.fieldNames.message} label={'متن پیام'} />
            </Grid> */}
            <Grid item xs={12} md={12}>
              <RHFTextareaField name={validation.fieldNames.code_melli} label={'کدملی'} />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            mt: 3,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {/* <LoadingButton
            fullWidth
            // type="click"
            variant="outlined"
            color="success"
            loading={isLoading}
            onClick={onClose}
          >
            {'لغو'}
          </LoadingButton> */}

          <LoadingButton type="submit" variant="contained" color={'success'} loading={isLoading}>
            {'ثبت اطلاعات'}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AddToCaravanWithCodeMeli;
