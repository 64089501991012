import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, MenuItem, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../../../enumeration';
import { fDate, fDateJalali, fDateTime } from '../../../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../../../components/table/TableMoreMenu';
import Iconify from '../../../../../components/Iconify';
import MoreTable from '../../../../../components/table/MoreTable';
import MoreTableItem from '../../../../../components/table/MoreTable/MoreTableItem';
import { Interweave } from 'interweave';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { LoadingButton } from '@mui/lab';

export const tableData = ({ baseRoute, onDelete, disabled ,resendNotif,resendLoading, travelId }) => [
  {
    key: '',
    header: 'متن پیام',
    // Component: ({ param }) => param?.message || '-',
    Component: ({ param }) => (
      <Box sx={{ width: '200px' }}>
        <Typography
          noWrap
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            height: '42px',
          }}
        >
          {/*{param?.message || '-'}*/}
          <Interweave
            content={param?.message || '-'}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
            }}
          />
        </Typography>
      </Box>
    ),
  },
  // {
  //   key: '',
  //   header: 'نتیجه ارسال',
  //   Component: ({ param }) => param?.status || '-',
  // },
  {
    key: '',
    header: 'تاریخ ارسال',
    Component: ({ param }) => (param?.created_at && fDateTime(param?.created_at)) || '-',
  },
  {
    key: '',
    header: 'ارسال کننده',
    Component: ({ param }) => `${param?.admin_id?.name || '---'} ${param?.admin_id?.last_name || ""}`,
  },
  {
    key: 'history',
    header: '',
    Component: ({ param }) => (
      <>
          <LoadingButton
            variant="outlined"
            color="success"
          // endIcon={<Download />}
            onClick={() => resendNotif(param)}
            loading={resendLoading == param?.id}
            disabled={resendLoading != -1}
          >
            {'باز ارسال پیام'}
          </LoadingButton>
      </>
    ),
    free: true,
  },
  {
    key: 'actions',
    header: '',
    Component: ({ param }) =>
      !disabled?.update || !disabled?.delete ? (
        <MoreTable>
          {!disabled?.update ? (
            <Link to={`${PATH_DASHBOARD.notifications.root + `/${travelId}` + PATH_DASHBOARD.notifications.show}/${param?.id}?back_url=${window.location.pathname}`}>
              <MoreTableItem label={'مشاهده'} icon={<VisibilityIcon />} />
            </Link>
          ) : (
            ''
          )}
          {!disabled?.delete ? <MoreTableItem isDelete onClick={() => onDelete(param)} /> : ''}
        </MoreTable>
      ) : (
        ''
      ),
    free: true,
  },
  // {
  //   key: '',
  //   header: 'ارسال شده به',
  //   Component: ({ param }) => <Box style={{ direction: 'ltr' }}>{param?.send_to?.replace(/\n/g, ` , `) || '-'}</Box>,
  // },
];
