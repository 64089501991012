/* eslint-disable camelcase */
import { cloneElement, isValidElement, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Container, Typography, Button, Stack } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

import { Link, Navigate, NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Enum from '../enum';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import useSettings from '../../../hooks/useSettings';
import axiosInstance from '../../../utils/axios';
import { useQueryCustom } from '../../../utils/reactQueryHooks';
import WaitingBox from '../../../components/WaitingBox/index';
import useQueryString from '../../../utils/useQueryString';
import AgentComponent from './Agent/List';
import ClientComponent from './Client/List';
import SocialComponent from './Social';
import SelectorComponent from '../../../components/hook-form/Selector';
import { LoadingButton } from '@mui/lab';
import { Download } from '@mui/icons-material';
import api from 'src/services/api';
import buildAccess from 'src/permission/buildAccess';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { hasAccess } from 'src/permission/utiles';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import HajDatepickerExcel from './hajExportModal';
import ModalLayout from 'src/components/ModalLayout';
import { useSelector } from 'react-redux';

const ShowCaravansManagementLayout = ({ children, showClientButton }) => {
  const { t } = useTranslation();
  const ModalLayoutQuestionInputRef = useRef();
  const admin = useSelector((store) => store.admin.data);

  const hideForCaravanAgent = useMemo(()=> 
    admin?.agent?.id == 1 || admin?.agent?.id == 2 || admin?.agent?.id == 3 || admin?.agent?.id == 4 ||
    admin?.agent?.name == "مدیر کاروان" || admin?.agent?.name == "روحانی" || admin?.agent?.name == "معینه" || admin?.agent?.name == "معاون کاروان" 
  , [admin])
  
  const queryParams = useParams();
  const queryString = useQueryString();
  const location = useLocation();
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const previousQuery = location?.state?.query;
  const params = new URLSearchParams(previousQuery || {});
  const backUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}`;
  const paramId = queryParams?.id;
  const currentBase = `${backUrl}/show/${paramId}`;

  const notifaccess = buildAccess(PATH_DASHBOARD.notifications.name)

  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${paramId}`);
  const dataById = useQueryCustom({
    name: `get_by_Id_${Enum?.api?.base}_${paramId}`,
    url: getById,
    enabled: !!paramId,
  });

  const caravan = dataById?.data?.data;
  const [DownloadExcelLoading, setDownloadExcelLoading] = useState(false)
  const [DownloadHajExcelLoading, setDownloadHajExcelLoading] = useState(false)
  const [DownloadZipLoading, setDownloadZipLoading] = useState(false)

  const handleDownloadExcel = async () => {
    const { filters, ...others } = queryString;
    const newFilters = [];
    // if (filters) newFilters = JSON.parse(filters || '')?.map((x) => ({ input_id: x.input_id, value: x.value }));
    // console.log('* * * getting : ', { newFilters , others});
    delete newFilters?.page;
    delete newFilters?.perPage;
    delete others?.page;
    delete others?.perPage;


    // handleShowDownloadStausModal();

    try {
      setDownloadExcelLoading(true)
      const response = await axiosInstance.get(`${Enum?.api?.excelCaravanClients(queryParams.travel)}`, {
        responseType: 'blob',
        params: {
          team_id: queryParams?.id,
          ...others,
          ...(newFilters && (newFilters)),
          // ...(filters && { filters: JSON.stringify(newFilters) }),
        },
        // onDownloadProgress: (progressEvent) => {
        //   const { loaded, total } = progressEvent;

        //   // Calculate the percentage of the file downloaded
        //   // const progressPercentage = (loaded / total) * 100;
        //   const loadedInMB = (loaded / 1024 / 1024).toFixed(2); // Loaded size in MB

        //   setDownloadProgress(loadedInMB);

        //   // Set the total size of the file (only once)
        //   if (fileSize === 0 && total) {
        //     setFileSize(total); // Set total size in bytes
        //   }
        // },
      });

      console.log({ downloadExcelData: response?.data })

      const blob = new Blob([response.data]);

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      // link.href = response.data;
      link.download = 'caravan_client.xlsx';
      link.click();

      // Clean up and revoke the object URL after download
      window.URL.revokeObjectURL(link.href);

      // // await downloadFileFromAxios(data , "client");
      // handleShowDownloadStausModal();
      // setDownloadProgress(0)
      setDownloadExcelLoading(false)
    } catch (error) {
      console.log(error);
      // handleShowDownloadStausModal();
      setDownloadExcelLoading(false)
    }

  }

  const handleDownloadClientImages = async () => {
    const { filters, ...others } = queryString;
    const newFilters = [];
    // if (filters) newFilters = JSON.parse(filters || '')?.map((x) => ({ input_id: x.input_id, value: x.value }));
    // console.log('* * * getting : ', { newFilters , others});
    delete newFilters?.page;
    delete newFilters?.perPage;
    delete others?.page;
    delete others?.perPage;


    // handleShowDownloadStausModal();

    try {
      setDownloadZipLoading(true)
      const response = await axiosInstance.get(`${Enum?.api?.zipCaravanClientsImage}`, {
        responseType: 'blob',
        params: {
          team_id: queryParams?.id,
          // ...others,
          // ...(newFilters && (newFilters)),
          // ...(filters && { filters: JSON.stringify(newFilters) }),
        },
        // onDownloadProgress: (progressEvent) => {
        //   const { loaded, total } = progressEvent;

        //   // Calculate the percentage of the file downloaded
        //   // const progressPercentage = (loaded / total) * 100;
        //   const loadedInMB = (loaded / 1024 / 1024).toFixed(2); // Loaded size in MB

        //   setDownloadProgress(loadedInMB);

        //   // Set the total size of the file (only once)
        //   if (fileSize === 0 && total) {
        //     setFileSize(total); // Set total size in bytes
        //   }
        // },
      });


      const blob = new Blob([response.data]);

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      // link.href = response.data;
      link.download = `عکس های پرسنلی-${caravan?.name}.zip`;
      link.click();

      // Clean up and revoke the object URL after download
      window.URL.revokeObjectURL(link.href);

      // // await downloadFileFromAxios(data , "client");
      // handleShowDownloadStausModal();
      // setDownloadProgress(0)
      setDownloadZipLoading(false)
    } catch (error) {
      console.log(error);
      // handleShowDownloadStausModal();
      setDownloadZipLoading(false)
    }

  }

  const handleDownloadHajExcel = async (from_date , to_date) => {
    const { filters, ...others } = queryString;
    const newFilters = [];
    // if (filters) newFilters = JSON.parse(filters || '')?.map((x) => ({ input_id: x.input_id, value: x.value }));
    // console.log('* * * getting : ', { newFilters , others});
    delete newFilters?.page;
    delete newFilters?.perPage;
    delete others?.page;
    delete others?.perPage;


    // handleShowDownloadStausModal();

    try {
      setDownloadHajExcelLoading(true)
      const response = await axiosInstance.get(`${Enum?.api?.hajExcel(queryParams?.id)}`, {
        responseType: 'blob',
        params: {
          from_date: (from_date && from_date !== "") ?  from_date : undefined,
          to_date: (to_date && to_date !== "") ? to_date : undefined,
          // team_id: queryParams?.id,
          // ...others,
          // ...(newFilters && (newFilters)),
          // ...(filters && { filters: JSON.stringify(newFilters) }),
        },
        // onDownloadProgress: (progressEvent) => {
        //   const { loaded, total } = progressEvent;

        //   // Calculate the percentage of the file downloaded
        //   // const progressPercentage = (loaded / total) * 100;
        //   const loadedInMB = (loaded / 1024 / 1024).toFixed(2); // Loaded size in MB

        //   setDownloadProgress(loadedInMB);

        //   // Set the total size of the file (only once)
        //   if (fileSize === 0 && total) {
        //     setFileSize(total); // Set total size in bytes
        //   }
        // },
      });


      const blob = new Blob([response.data]);

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      // link.href = response.data;
      link.download = `اکسل سازمان حج-${caravan?.name}.xlsx`;
      link.click();

      // Clean up and revoke the object URL after download
      window.URL.revokeObjectURL(link.href);

      // // await downloadFileFromAxios(data , "client");
      // handleShowDownloadStausModal();
      // setDownloadProgress(0)
      setDownloadHajExcelLoading(false)
      ModalLayoutQuestionInputRef.current.show((p) => false);
    } catch (error) {
      console.log(error);
      // handleShowDownloadStausModal();
      setDownloadHajExcelLoading(false)
    }

  }

  const handleQuestionModal = () => ModalLayoutQuestionInputRef.current.show((p) => !p);

  console.log({ dataById, location, previousQuery });
  const caravanTabType = Enum.enumTab.object;
  const tab = [
    {
      ...caravanTabType.client,
      // active: !queryParams.tab && true,
      hasAccess: true,
      side: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            ml: 'auto',
          }}
        >
          {!hideForCaravanAgent && (
            <>
              {showClientButton ? (
                <Button onClick={showClientButton?.onClick} variant="outlined" color="success">
                  {showClientButton?.label}
                </Button>
              ) : (
                ''
              )}
            </>
          )}

          {/* <Button variant="grey">
            <PrintIcon
              sx={{
                cursor: 'pointer',
              }}
            />
          </Button> */}

          <LoadingButton
            variant="outlined"
            color={'success'}
            startIcon={<Download />}
            onClick={handleDownloadExcel}
            loading={DownloadExcelLoading}
          >
            دریافت اکسل
          </LoadingButton>

          {!hideForCaravanAgent && (
            <>
              <Button
                variant="contained"
                color={'success'}
                component={Link}
                to={`${currentBase}/${caravanTabType.client.value}/add-codemelli`}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                کاروان بندی باکدملی
              </Button>
              <Button
                variant="contained"
                color={'success'}
                component={Link}
                to={`${currentBase}/${caravanTabType.client.value}/add`}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                {'کاروان بندی'}
              </Button>
            </>
          )}

        </Box>
      ),
    },
    {
      ...caravanTabType.agent,
      hasAccess: true,
      side: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            ml: 'auto',
          }}
        >
          {/* <Button variant="grey">
            <PrintIcon
              sx={{
                cursor: 'pointer',
              }}
            />
          </Button> */}

          {/* <Button variant="contained" color={'success'} startIcon={<Iconify icon={'eva:plus-fill'} />}>
            {'افزودن کابر'}
          </Button> */}
        </Box>
      ),
    },
    // {
    //   ...caravanTabType.nezam,
    //   active: false,
    // },
    // {
    //   ...caravanTabType.bank,
    //   active: false,
    //   side: (
    //     <Box
    //       sx={{
    //         display: 'flex',
    //         alignItems: 'center',
    //         gap: 2,
    //         ml: 'auto',
    //       }}
    //     >
    //       {/* <Button variant="grey">
    //         <PrintIcon
    //           sx={{
    //             cursor: 'pointer',
    //           }}
    //         />
    //       </Button> */}

    //       {/* <Button variant="contained" color={'success'} startIcon={<Iconify icon={'eva:plus-fill'} />}>
    //         {'افزودن کابر'}
    //       </Button> */}

    //       {/* <Button variant="outlined" color="success">
    //         {'صدور کارت'}
    //       </Button> */}
    //     </Box>
    //   ),
    // },
    {
      ...caravanTabType.message,
      active: false,
      side: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            ml: 'auto',
          }}
        >
          {/* <Button variant="grey">
            <PrintIcon
              sx={{
                cursor: 'pointer',
              }}
            />
          </Button> */}
          <Button
            variant="contained"
            color={'success'}
            component={Link}
            to={`${currentBase}/${caravanTabType.message.value}/add`}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            {'ارسال پیام'}
          </Button>
        </Box>
      ),
      hasAccess: hasAccess(notifaccess.read)
    },
    {
      ...caravanTabType.social,
      active: false,
      hasAccess: true,
      // side: (
      //   <Box
      //     sx={{
      //       display: 'flex',
      //       alignItems: 'center',
      //       gap: 2,
      //       ml: 'auto',
      //     }}
      //   >
      //     <Button
      //       variant="contained"
      //       color={'success'}
      //       component={Link}
      //       to={`${currentBase}/${caravanTabType.social.value}/add`}
      //       startIcon={<Iconify icon={'eva:plus-fill'} />}
      //     >
      //       {'افزودن'}
      //     </Button>
      //   </Box>
      // ),
      // side: (
      //   <Box
      //     sx={{
      //       display: 'flex',
      //       alignItems: 'center',
      //       gap: 2,
      //       ml: 'auto',
      //     }}
      //   >
      //     <Button variant="grey">
      //       <PrintIcon
      //         sx={{
      //           cursor: 'pointer',
      //         }}
      //       />
      //     </Button>

      //     <Button
      //       variant="contained"
      //       color={'success'}
      //       component={Link}
      //       to={`${currentBase}/${caravanTabType.social.value}/edit`}
      //       startIcon={<Iconify icon={'eva:plus-fill'} />}
      //     >
      //       {'ویرایش'}
      //     </Button>
      //   </Box>
      // ),
    },
    // {
    //   ...caravanTabType.tutorial,
    //   active: false,
    //   side: (
    //     <Box
    //       sx={{
    //         display: 'flex',
    //         alignItems: 'center',
    //         gap: 2,
    //         ml: 'auto',
    //       }}
    //     >
    //       {/* <Button variant="grey">
    //         <PrintIcon
    //           sx={{
    //             cursor: 'pointer',
    //           }}
    //         />
    //       </Button> */}

    //       <Button variant="contained" color={'success'} startIcon={<Iconify icon={'eva:plus-fill'} />}>
    //         {'افزودن'}
    //       </Button>
    //     </Box>
    //   ),
    // },
    // {
    //   ...caravanTabType.more,
    //   active: false,
    // },
  ];
  // const tabOptions=useMemo(()=>{
  //   return tab?.map((x) =>({label:}));
  // },[queryString])

  const selectedTab = useMemo(() => {
    return tab?.find((x) => queryParams.tab === x.value || x.active);
  }, [queryString]);

  // useEffect(()=>{
  //   // if(!queryString.type)
  // },[])
  console.log({ aaaaa: `${backUrl}/${'aaa'}/` });

  const title = caravan?.name || '';
  // `${caravan?.name || ''} - ${caravan?.province?.name || ''}`;
  // const title = ` ${Enum.title.name[0]} ${caravan?.id || ''} - ${caravan?.province?.name || ''}`;

  const appendedPropsToChildren = () => {
    if (isValidElement(children)) {
      return cloneElement(children, {
        caravan,
      });
    }
    return <>{children || ' '}</>;
  };

  return dataById.isLoading ? (
    <WaitingBox />
  ) : (
    <Page title={`${title}`}>

      <ModalLayout ref={ModalLayoutQuestionInputRef}>

        <Box
          sx={{
            width: '100%',
            maxWidth: '550px',
            // display: { xs: 'block', md: 'flex' },
            gap: 2,
            bgcolor: 'background.paper',
            borderRadius: 1,
            px: 3,
            py: 3,
          }}
        >

          <Typography sx={{
            fontWeight: 700,
            fontSize: 18,
            textAlign: "center",
            mb: 4
          }}>لطفا در صورت نیاز بازه مورد نظر را انتخاب کنید</Typography>
            <HajDatepickerExcel 
              handleCallFunction={handleDownloadHajExcel}
              loading={DownloadHajExcelLoading}
            />



        </Box>

      </ModalLayout>


      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          back={`${backUrl}?${params.toString()}`}
          links={[
            { name: 'داشبورد', href: Enum.routes.root(queryParams.type, queryParams.travel) },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
          action={
            <>
              <Stack direction={"row"} gap={1}>
                <LoadingButton
                  variant="outlined"
                  color={'success'}
                  startIcon={<Download />}
                  // onClick={handleDownloadHajExcel}
                  onClick={handleQuestionModal}
                  loading={DownloadHajExcelLoading}
                >
                  دریافت اکسل سازمان حج
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  color={'success'}
                  startIcon={<Download />}
                  onClick={handleDownloadClientImages}
                  loading={DownloadZipLoading}
                >
                  دریافت عکس های پرسنلی
                </LoadingButton>
              </Stack>
            </>
          }
        />
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
          }}
        >
          <Box
            sx={{
              // display: 'flex',
              alignItems: 'center',
              gap: 2,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {tab?.map((x) => {
              if (!x?.hasAccess) {
                return <></>
              }
              return (
                <Link to={`${currentBase}/${x.value}`}>
                  <Typography
                    sx={{
                      color: 'grey.800',
                      p: 1,
                      borderBottom: '2px solid transparent',
                      fontWeight: '300',
                      fontSize: '13px',

                      ...(queryParams.tab === x.value
                        ? {
                          borderColor: 'grey.800',
                          fontWeight: '900',
                        }
                        : {
                          '&:hover': {
                            color: 'grey.600',
                          },
                        }),
                    }}
                  >
                    {x.label}
                  </Typography>
                </Link>
              );
            })}
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              gap: 2,
              display: { md: 'none', xs: 'flex' },
            }}
          >
            <SelectorComponent
              sx={{
                width: '200px',
              }}
              options={tab}
              onChange={(x) => {
                console.log({ x });
                navigate(`${currentBase}/${x.value}`);
              }}
              value={selectedTab}
              isClearable={false}
            />
          </Box>

          <Box
            sx={{
              ml: 'auto',
            }}
          >
            {selectedTab?.side}
          </Box>
        </Box>

        <Box
          sx={{
            mt: 3,
          }}
        >
          {/* {selectedTab?.component} */}

          {appendedPropsToChildren()}
        </Box>
      </Container>
    </Page>
  );
};

export default ShowCaravansManagementLayout;
