import { agentsType } from 'src/enumeration';
import * as yup from 'yup';
import errorsText from '../../../utils/errorsText';
import persianToEnglishNumber from '../../../utils/persianToEnglishNumber';

const fieldNames = {
  name: 'name',
  caravan_id: 'caravan_id',
  manager_id: 'manager_id',
  rohani_id: 'rohani_id',
  deputy_id: 'deputy_id',
  woman_rohani_id: 'woman_rohani_id',
  university_category_id: 'university_category_id',
  university_id: 'university_id',
  iran_province_id: 'iran_province_id',
  address: 'address',
  flight_station: 'flight_station',
  dispatch_date: 'dispatch_date',
  period_from: 'period_from',
  period_to: 'period_to',
  meeting_date: 'meeting_date',
  baj_number: 'baj_number',
  capacity: 'capacity',
  agent_capacity: 'agent_capacity',
  storage_capacity: 'storage_capacity',
  storage_max: 'storage_max',
  price: 'price',
  border: 'border',
  border_code: 'border_code',
  is_lottery: 'is_lottery',
  team_type: 'team_type',
  team_grade: 'team_grade',
  interface_id: 'interface_id',
  status: 'status',
  active: 'active',
  travel_id: 'travel_id',
  socials: 'socials',
  link: 'link',
  social: 'social',
  teamSocialId: 'teamSocialId',
  agents: 'agents',
  agent: 'agent',
  admin: 'admin',
  men_count: 'men_count',
  women_count: 'women_count',
  married_count: 'married_count',
  triple_bed: 'triple_bed',
  double_bed: 'double_bed',
  single_bed: 'single_bed',
};

// const willBe= [ {
//   agent: {
//     keyId: uniqueId(),
//     value: agentsType.managerCaravan.value,
//     label: 'مدیر کاروان',
//   },
// },
// {
//   agent: {
//     keyId: uniqueId(),
//     value: agentsType.rohani.value,
//     label: 'روحانی کاروان',
//   },
// }]

const socials = yup
  .object({
    [fieldNames.social]: yup.mixed(),
    [fieldNames.link]: yup.string().nullable(),
  })
  .defined();

const agents = yup
  .object({
    [fieldNames.agent]: yup.mixed()
    // .required(errorsText.blankError())
    ,
    [fieldNames.admin]: yup.mixed()
    // .required(errorsText.blankError())
    ,
  })
  .defined();

const validation = {
  // defaultValue:{},
  fieldNames,
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.name]: yup.string().nullable(),
      [validation.fieldNames.caravan_id]: yup.string().nullable(),

      // [validation.fieldNames.agents]: yup.array(),
      // [validation.fieldNames.manager_id]: yup.mixed(),
      // [validation.fieldNames.rohani_id]: yup.mixed(),
      [validation.fieldNames.university_category_id]: yup.mixed(),
      [validation.fieldNames.university_id]: yup.mixed(),
      [validation.fieldNames.iran_province_id]: yup.mixed(),
      [validation.fieldNames.iran_province_id]: yup.mixed(),
      [validation.fieldNames.address]: yup.string().nullable(),
      [validation.fieldNames.flight_station]: yup.string().nullable(),
        [validation.fieldNames.dispatch_date]: yup.mixed(),
      /*[validation.fieldNames.period_from]: yup
        .mixed()

        .test('is-greater', errorsText.dateIsLast(), (value) => {
          const now = new Date();
          now.setHours(0, 0, 0, 0);
          const from = new Date(value);
          console.log({ now, from }, now <= from, value);
          return !value || now <= from;
        }),
      [validation.fieldNames.period_to]: yup
        .mixed()

        .test('is-greater', errorsText.startDateIsGreater(), function (value) {
          const { [validation.fieldNames.period_from]: periodFrom } = this.parent;
          const from = +new Date(periodFrom);
          const end = +new Date(value);
          return !periodFrom || !value || from < end;
        }),*/
      [validation.fieldNames.capacity]: yup
        .number()
        .min(0, errorsText.min(0))
        .typeError(errorsText.blankError())
        // .required(errorsText.blankError())
        ,
      [validation.fieldNames.agent_capacity]: yup
        .number()
        .min(0, errorsText.min(0))
        .typeError(errorsText.blankError())
        // .required(errorsText.blankError())
        ,
      [validation.fieldNames.meeting_date]: yup.mixed(),
      [validation.fieldNames.baj_number]: yup.string().nullable(),
      [validation.fieldNames.men_count]: yup
        .number()
        .nullable()
        .min(0, errorsText.min(0))
        .typeError(errorsText.blankError())
        // .required(errorsText.blankError())
        .test('is-greater', `مجموع نباید بیشتر از ظرفیت باشد`, function (a) {
          const {
            [validation.fieldNames.capacity]: value,
            [validation.fieldNames.women_count]: b,
            [validation.fieldNames.married_count]: c,
          } = this.parent;
          const total = (a || 0) + (b || 0) + (c || 0);
          // console.log('* * men_count :', { total, value }, a, b, c, +total <= +value);
          return +total <= +value;
        }),
      [validation.fieldNames.women_count]: yup
        .number()
        .min(0, errorsText.min(0))
        .typeError(errorsText.blankError())
        // .required(errorsText.blankError())
        .test('is-greater', `مجموع نباید بیشتر از ظرفیت باشد`, function (b) {
          const {
            [validation.fieldNames.capacity]: value,
            [validation.fieldNames.men_count]: a,
            [validation.fieldNames.married_count]: c,
          } = this.parent;
          const total = (a || 0) + (b || 0) + (c || 0);
          // console.log('* * women_count :', { total, value }, a, b, c, +total <= +value);

          return +total <= +value;
        }),
      [validation.fieldNames.married_count]: yup
        .number()
        .min(0, errorsText.min(0))
        .typeError(errorsText.blankError())
        // .required(errorsText.blankError())
        .test('is-greater', `مجموع نباید بیشتر از ظرفیت باشد`, function (c) {
          const {
            [validation.fieldNames.capacity]: value,
            [validation.fieldNames.men_count]: a,
            [validation.fieldNames.women_count]: b,
          } = this.parent;
          const total = (a || 0) + (b || 0) + (c || 0);
          // console.log('* * married_count :', { total, value }, a, b, c, +total <= +value);

          return +total <= +value;
        }),

      [validation.fieldNames.storage_capacity]: yup
        .number()
        .min(0, errorsText.min(0))
        .typeError(errorsText.blankError()),
      [validation.fieldNames.storage_max]: yup.number().min(0, errorsText.min(0)).typeError(errorsText.blankError()),
      [validation.fieldNames.price]: yup.string().nullable(),
      [validation.fieldNames.border]: yup.string().nullable(),
      [validation.fieldNames.border_code]: yup.string().nullable(),
      [validation.fieldNames.is_lottery]: yup.mixed()
      // .required(errorsText.blankError())
      ,
      [validation.fieldNames.team_type]: yup.mixed()
      // .required(errorsText.blankError())
      ,
      [validation.fieldNames.team_grade]: yup.string().nullable(),
      // [validation.fieldNames.interface_id]: yup.mixed(),
      [validation.fieldNames.active]: yup.mixed()
      // .required(errorsText.blankError())
      ,
      [validation.fieldNames.price]: yup.string().nullable(),

      [validation.fieldNames.socials]: yup.array().of(socials),
      [validation.fieldNames.agents]: yup.array().of(agents),
      // .test('valid-agents', 'باید مدیر کاروان و روحانی کاروان مشخص شود', (value) => {
      // //   console.log('* * * validation : ', { value });

      //   const is = value.filter(
      //     (agent) =>
      //       agent[fieldNames.agent]?.value === agentsType.managerCaravan.value ||
      //       agent[fieldNames.agent]?.value === agentsType.rohani.value
      //   );
      //   return is?.length >= 2;
      // }),
    }),
};

export default validation;
