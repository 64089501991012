import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FormProvider } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";

const HajDatepickerExcel = ({
    handleCallFunction = () => null,
    loading
}) => {

    const methods = useForm({
        mode: 'all',
        shouldUnregister: false,
        defaultValues: {
            from_date: "",
            to_date: ""
        },
    });

    const {
        reset,
        watch,
        control,
        setValue,
        setError,
        handleSubmit,
        getValues,
        formState: { errors, isSubmitting },
        trigger,
    } = methods;


    const onSubmit = () => {

        const values = getValues();

        if(values.from_date && values.from_date !== ""){
            if(!values.to_date || values.to_date == ""){
                toast.error("لطفا بازه تاریخ را کامل انتخاب کنید")
                return
            }
        }

        if(values.to_date && values.to_date !== ""){
            if(!values.from_date || values.from_date == ""){
                toast.error("لطفا بازه تاریخ را کامل انتخاب کنید")
                return
            }
        }

        handleCallFunction(values.from_date, values.to_date)
    }


    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack direction={"row"} gap={1.5}>
                    <RHFDatePicker name={"from_date"} label={'بازه از تاریخ'} withTime />
                    <RHFDatePicker name={"to_date"} label={'بازه تا تاریخ'} withTime />
                </Stack>
                <LoadingButton
                    variant="contained"
                    color={'success'}
                    // onClick={handleDownloadHajExcel}
                    onClick={onSubmit}
                    loading={loading}
                    sx={{
                        width:"100%",
                        mt:3
                    }}
                >
                    دریافت اکسل سازمان حج
                </LoadingButton>
            </FormProvider>
        </>
    );
}

export default HajDatepickerExcel;