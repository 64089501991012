import { Box, Grid, Modal, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import RHFSelector from 'src/components/hook-form/RHFSelector';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import validation from './validation';
import { useMutationCustom, useQueryCustom } from 'src/utils/reactQueryHooks';
import axiosInstance from 'src/utils/axios';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import WaitingBox from 'src/components/WaitingBox/index';
import persianToEnglishNumber from 'src/utils/persianToEnglishNumber';
import api from 'src/services/api';
import { useEffect, useMemo, useState } from 'react';
import { acceptFinallyStatus, documentStatus, sabtNahayiStatus } from 'src/enumeration';
import { useSelector } from 'react-redux';
import buildAccess from 'src/permission/buildAccess';
import { hasAccess } from 'src/permission/utiles';
import CaravanSelector from '../../../../../caravansManagement/selector';
import { PATH_DASHBOARD } from '../../../../../../routes/paths';
import SelectBed from '../../../../../caravansManagement/Show/Client/SelectBed';
import { CLIENT_TYPE_OP, PASSPORT_SENDING_STATUS } from 'src/config';

const UserStatusInfo = ({ data }) => {
  const admin = useSelector((store) => store.admin.data);
  const accessStatus = buildAccess("Status");

  const [searchParams] = useSearchParams();

  const { t } = useTranslation();
  const queryParams = useParams();
  const paramId = queryParams?.id;
  const travelId = queryParams?.course;
  // console.log({ queryParams,travelId });
  const navigate = useNavigate();

  const [toggleModal, setToggleModal] = useState();

  const handleSetModalData = (data) => setToggleModal(data);
  const handleToggleModal = () => setToggleModal((p) => !p);

  const [HasCaravan, setHasCaravan] = useState(false)


  const methods = useForm({
    resolver: yupResolver(validation.schema()),
    defaultValues:validation.defaultValues
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(api.changeStatus.base, params);
  const creatingCaravan = (params) => axiosInstance.post(api.caravan.client, params);
  const updating = (params) => axiosInstance.post(`${api.changeStatus.base}`, params);
  const getById = () => axiosInstance.get(`${api.changeStatus.base}?travel_register_id=${queryParams.course}`);

  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    navigate(PATH_DASHBOARD.visitorManagement.root("omre", searchParams.get("travel")) + "/registrations")
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const { isLoading, mutate } = useMutationCustom({
    url: paramId ? updating : creating,
    name: `${api.changeStatus.base}_update`,
    // invalidQuery: `${api.travelRegister.register.base}_get_${queryParams.course}`,
    invalidQuery: `${api.public.travelRegister.base}_getById_userStatus`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });


  // ---------------------------------------
  const onSuccess = (resData) => {
    console.log('* * * onSuccess', { resData });

    const resetData = {
      [validation.fieldNames.status]: acceptFinallyStatus[resData?.register?.status_num],
      [validation.fieldNames.sabt_nahayi_status]: sabtNahayiStatus[resData?.register?.sabt_nahayi_status_num],
      [validation.fieldNames.bankStatus]: documentStatus[resData?.register?.bank_status_num],
      [validation.fieldNames.dutyStatus]: documentStatus[resData?.register?.duty_status_num],
      [validation.fieldNames.identityStatus]: documentStatus[resData?.register?.identity_status_num],
      [validation.fieldNames.passport_sending_status]: PASSPORT_SENDING_STATUS[resData?.register?.passport_sending_status_num],
      [validation.fieldNames.carevan]: resData?.team?.id ? CaravanSelector.convertor.object(resData?.team) : null,
      [validation.fieldNames.type]: CLIENT_TYPE_OP.find(item => item.value === resData?.register?.client?.type),
      [validation.fieldNames.code]: resData?.register?.code,
      [validation.fieldNames.description]: resData?.register?.description,
    };

    if (resData?.team?.id) {
      setHasCaravan(true)
    } else {
      setHasCaravan(false)
    }

    console.log('* * * onSuccess', { resetData });
    reset(resetData);
  };

  const userStatus = useQueryCustom({
    url: getById,
    name: `${api.public.travelRegister.base}_getById_userStatus`,
    // params: { id: travel },
    // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
    onSuccess: (resData) => {
      onSuccess(resData?.data)
    },
    enabled: hasAccess(accessStatus.read)
    //  onError: onErrorMutating,
  });

  useEffect(() => {
    if (!data) return;
    onSuccess(data);
  }, [data]);
  const watchStartDate = watch(validation.fieldNames.startDate);
  console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();
    console.log('* * * onSubmit : ', { values });

    const reqData = {
      travel_register_id: travelId,
      [validation.fieldNames.status]: values[validation.fieldNames.status]?.value,
      [validation.fieldNames.sabt_nahayi_status]: values[validation.fieldNames.sabt_nahayi_status]?.value,
      // [validation.fieldNames.bankStatus]: values[validation.fieldNames.bankStatus]?.value,
      [validation.fieldNames.dutyStatus]: values[validation.fieldNames.dutyStatus]?.value,
      [validation.fieldNames.identityStatus]: values[validation.fieldNames.identityStatus]?.value,
      [validation.fieldNames.type]: values[validation.fieldNames.type]?.value,
      [validation.fieldNames.passport_sending_status]: values[validation.fieldNames.passport_sending_status]?.value,
      [validation.fieldNames.code]: values[validation.fieldNames.code],
      [validation.fieldNames.description]: values[validation.fieldNames.description],
    };

    console.log('* * * onSubmit : ', { reqData, values });
    mutate(reqData);
  };

  const onSuccessMutatingCaravan = () => {
    toast.success(t('successfully'));
    setToggleModal(false);
    // navigate(backUrl);
  };

  const mutateAdd = useMutationCustom({
    url: creatingCaravan,
    name: `user_status_caravan_update`,
    // invalidQuery: `${Enum?.api?.base}_get_${queryParams.travel}_${queryParams.id}`,
    invalidQueries: [
      `${api.public.travelRegister.base}_getById_userStatus`
    ],

    // invalidQuery: `${Enum?.api?.base}_get`,
    onSuccess: onSuccessMutatingCaravan,
    // onError: onErrorMutating,
  });

  const onSubmitCaravan = async (bed) => {
    console.log("onSubmitCaravan")
    const userData = userStatus?.data?.data?.register;
    const selectedCarevan = getValues(validation.fieldNames.carevan)?.data;

    const reqData = {
      team_id: selectedCarevan.id,
      clients: [
        {
          id: userData?.client?.id,
          bed: bed
        }
      ]
    };

    console.log({ reqData })

    const formData = new FormData();
    Object.keys(reqData || {})?.map((x) => {
      formData.append(x, reqData[x]);
    });

    mutateAdd.mutate(reqData);

  }

  const handleSelectBed = () => {
    const selectedCarevan = getValues(validation.fieldNames.carevan)?.data;
    const remainingBed = {
      single: selectedCarevan?.remaining_single_bed,
      double: selectedCarevan?.remaining_double_bed,
      triple: selectedCarevan?.remaining_triple_bed,
    }
    const userData = userStatus?.data?.data?.register;
    console.log({ userData, selectedCarevan })
    // if (
    //   (userData?.members === 2 && !remainingBed.double) ||
    //   (userData?.members === 3 && !remainingBed.triple) ||
    //   userData?.members < 1 ||
    //   userData?.members > 3
    // ) {
    //   return handleSetModalData(userData);
    // }
    // tableSetting.onSelectRow({ ...data, bed: data.members });
    onSubmitCaravan(data?.members);
  }



  console.log({ queryParams });
  return (
    <Box
      sx={{
        width: '100%',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >

      <Modal
        open={toggleModal}
        onClose={handleToggleModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 6,
        }}
      >
        <SelectBed
          {...{
            caravan: getValues(validation.fieldNames.carevan)?.data,
            onClose: handleToggleModal,
            onAccept: (data) => {
              console.log('* * * onAccept :', { data });
              // tableSetting.onSelectRow(data);
              onSubmitCaravan(data?.bed);
              handleToggleModal();
            },
            remainingBed: {
              single: getValues(validation.fieldNames.carevan)?.data?.remaining_single_bed,
              double: getValues(validation.fieldNames.carevan)?.data?.remaining_double_bed,
              triple: getValues(validation.fieldNames.carevan)?.data?.remaining_triple_bed,
            },
            data: toggleModal,
          }}
        // onClose={handleQuestionModal}
        />
      </Modal>
      {/* <Box
        sx={{
          textAlign: 'center',
          mb: '30px',
        }}
        onClick={() => navigate(backUrl)}
      >
        <Typography variant="h5">{'شما در حال ایجاد یک دوره جدید هستید!'}</Typography>
      </Box> */}

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.name} label={'نام'} />
            </Grid> */}

            <Grid item xs={12} md={6}>
              <RHFSelector
                name={validation.fieldNames.status}
                label={'وضعیت'}
                options={Object.values(acceptFinallyStatus)}
                disabled={admin?.role?.title?.trim() === "عامل استانی" || admin?.role?.title?.trim() === "استان" || !!admin?.province?.id}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFSelector
                name={validation.fieldNames.sabt_nahayi_status}
                label={'وضعیت ثبت نهایی'}
                options={Object.values(sabtNahayiStatus)}
                disabled={admin?.role?.title?.trim() === "عامل استانی" || admin?.role?.title?.trim() === "استان" || !!admin?.province?.id}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <RHFSelector
                name={validation.fieldNames.bankStatus}
                label={'وضعیت بانکی'}
                options={Object.values(documentStatus)}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <RHFSelector
                name={validation.fieldNames.dutyStatus}
                label={'نظام وظیفه'}
                options={Object.values(documentStatus)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFSelector
                name={validation.fieldNames.identityStatus}
                label={'مدارک هویتی'}
                options={Object.values(documentStatus)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFSelector
                options={CLIENT_TYPE_OP}
                name={validation.fieldNames.type}
                label={"نوع زائر"}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFSelector
                name={validation.fieldNames.passport_sending_status}
                label={'وضعیت تحویل گذرنامه'}
                options={Object.values(PASSPORT_SENDING_STATUS)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <RHFTextField
                name={validation.fieldNames.code}
                label={'کد بازیابی'}
                disabled={admin?.role?.title?.trim() === "عامل استانی" || admin?.role?.title?.trim() === "استان" || !!admin?.province?.id}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <RHFTextField
                name={validation.fieldNames.description}
                label={'توضیحات'}
                multiline
                rows={4}
                disabled={admin?.role?.title?.trim() === "عامل استانی" || admin?.role?.title?.trim() === "استان" || !!admin?.province?.id}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            mt: 3,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {/* <LoadingButton
            fullWidth
            // type="click"
            variant="outlined"
            color="success"
            loading={isLoading}
            onClick={onClose}
          >
            {'لغو'}
          </LoadingButton> */}

          {hasAccess(accessStatus.update) &&
            <>
              <LoadingButton type="submit" variant="contained" color={'success'} loading={isLoading}>
                {'ثبت اطلاعات'}
              </LoadingButton>
            </>
          }

        </Box>
      </FormProvider>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitCaravan)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
            backgroundColor: 'grey.main',
            borderRadius: '8px',
            my: 3
          }}
        >
          <Typography>انتخاب کاروان</Typography>

        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <CaravanSelector.Element
              name={validation.fieldNames.carevan}
              queryParams={{
                travel_id: searchParams.get("travel")
              }}
              label={'کاروان'}
              disabled={HasCaravan}
            />
          </Grid>
        </Grid>
      </FormProvider>
      <Box
        sx={{
          display: 'flex',
          mt: 3,
          gap: 3,
          justifyContent: 'center',
        }}
      >

        {hasAccess(accessStatus.update) &&
          <>
            {HasCaravan ?
              <>
                <LoadingButton variant="contained" color={'error'} loading={mutateAdd?.isLoading || isLoading} onClick={() => onSubmitCaravan(0)}>
                  {'حذف از کاروان'}
                </LoadingButton>
              </>
              :
              <>
                <LoadingButton variant="contained" color={'success'} loading={mutateAdd?.isLoading || isLoading} onClick={handleSelectBed}>
                  {'اضافه کردن به کاروان'}
                </LoadingButton>
              </>
            }
          </>
        }

      </Box>
    </Box>
  );
};

export default UserStatusInfo;
