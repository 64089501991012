/* eslint-disable */
import { createPortal } from 'react-dom';
import { Box, Button, MenuItem, Stack, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'src/services/api';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useParams } from 'react-router-dom';
import axiosInstance from 'src/utils/axios';
import { useTranslation } from 'react-i18next';
import { useMutationCustom } from 'src/utils/reactQueryHooks';
import ModalLayout from '../../../../components/ModalLayout';
import QuestionComponent from '../../../../components/ui/Question/index';
import Reason from './Reason';
import { LoadingButton } from '@mui/lab';
import DoneIcon from '@mui/icons-material/Done';
import { formTypesObject } from 'src/enumeration';
import Image from 'src/components/Image';
import { Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import axiosInstanceV2 from 'src/utils/axiosV2';


const AcceptOrRejectWithQuestionsWithReason = ({
  icon,
  iconSx,
  label,
  isDelete,
  isEdit,
  sx,
  question,
  onClick,
  loading,
  disabled,
  id,
  confirm_status,
  reason,
  history,
  type,
  useV1 = false,
  index,
  ...props
}) => {
  console.log('* * * AcceptOrRejectWithQuestionsWithReason :', { history, type });
  const queryParams = useParams();

  const { t } = useTranslation();
  const [showReason, setShowReason] = useState();
  const ModalLayoutRemoveInputRef = useRef();
  const [RefetchLoading, setRefetchLoading] = useState(false)

  // ----------------------------------------------------------------------------- SERVICE
  const creatingV1 = (params) => axiosInstance.post('/admin/confirm_input', { ...params, client_input_id: id });
  const creating = (params) => axiosInstanceV2.post('/admin/confirm_input', params);
  // ------------------------------------------------------------------------------ Mutation
  
  const queryClient = useQueryClient();

  const handleInvalidQuery = () => {
    console.log("call Invalid")
    queryClient.invalidateQueries(`${api.travelRegister.register.base}_get_${queryParams.course}`);
  };
  
  const onSuccessMutating = () => {
    setShowReason();
    // handleInvalidQuery();
    ModalLayoutRemoveInputRef.current.show(false);
    toast.success(t('successfully'));
    props?.refetch?.();
    setRefetchLoading(false)
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };



  const { isLoading, mutate } = useMutationCustom({
    url:useV1 ?  creatingV1 : creating,
    name: `${'/admin/confirm_input'}_update`,
    // invalidQuery: `${api.travelRegister.register.base}_get_${queryParams.course}`,
    // invalidQueries: [`${api.travelRegister.register.base}_get_${queryParams.course}`],
    // onSuccess: onSuccessMutating,
    onSuccess: async () => {
      setRefetchLoading(true);
      // Invalidate the query
      console.log("call Invalid")
      await queryClient.invalidateQueries(`${api.travelRegister.register.base}_get_${queryParams.course}`);
      // Call your onSuccessMutating after query invalidation and refetching
      onSuccessMutating();
    },
    // onError: onErrorMutating,
  });

  const handleSubmitReject = (data) => {
    const helpArr = [];

    for (let i = 0; i < props?.UserCanSeeInputs?.length; i++) {
      const element = props?.UserCanSeeInputs?.[i];
      for (let j = 0; j < element?.client_inputs?.length; j++) {
        const client_element = element?.client_inputs?.[j];
        if(id == client_element?.id){
          helpArr.push({
            ...data,
            confirm_status: 0,
            client_input_id : id ,
          })
        }else{
          if(client_element?.id){
            helpArr.push({
              client_input_id : client_element?.id ,
              // confirm_status: client_element?.confirm_status,
              // reason: client_element?.reason,
            })
          }
        }
      }
    }
    console.log({
      tttttt : props?.UserCanSeeInputs,
      helpArr
    })
    if(useV1){
      mutate({ ...data, confirm_status: 0 });
    }else{
      mutate({client_input_id : helpArr});
    }
  };

  const handleSubmitAccept = () => {

    const helpArr = [];

    for (let i = 0; i < props?.UserCanSeeInputs?.length; i++) {
      const element = props?.UserCanSeeInputs?.[i];
      for (let j = 0; j < element?.client_inputs?.length; j++){
        const client_element = element?.client_inputs?.[j];

        if(id == client_element?.id){
          helpArr.push({
            client_input_id : id ,
            confirm_status: 1
          })
        }else{
          if(client_element?.id){
            helpArr.push({
              client_input_id : client_element?.id ,
              // confirm_status: client_element?.confirm_status,
              // reason: client_element?.reason,
            })
          }
        }
      }
    }

    console.log({
      tttttt : props?.UserCanSeeInputs,
      helpArr
    })

    // return
    if(useV1){
      mutate({ confirm_status: 1 });
    }else{
      mutate({client_input_id : helpArr});
    }
    ModalLayoutRemoveInputRef.current.show(false);
  };

  const handleQuestionModal = (params) => {
    ModalLayoutRemoveInputRef.current.show((p) => !p, params);
  };

  const handleClick = (key, e) => {
    // e.preventDefault();

    handleQuestionModal(questionsText[key]);
    setShowReason(false);
  };

  const questionsText = {
    reject: {
      title: 'question.reject.title',
      // description: 'question.reject.description',
      reason: {
        label: 'دلیل رد کردن',
      },
      button: {
        confirm: {
          label: 'question.reject.yes',
          onClick: () => setShowReason(true),
          loading: isLoading || RefetchLoading,
        },
        reject: {
          label: 'question.reject.no',
          onClick: () => handleQuestionModal(),
        },
      },
      // content:
    },
    accept: {
      title: 'آیا مطمئن به تایید این مورد هستید؟',
      // description: 'اگر تایید کنید دیگر قابل تغییر نمی باشد.آیا ادامه میدهید؟',
      button: {
        confirm: {
          label: 'بله ، تایید میکنم',
          onClick: handleSubmitAccept,
          loading: isLoading || RefetchLoading,
        },
        reject: {
          label: 'خیر',
          onClick: () => handleQuestionModal(),
          disabled: isLoading || RefetchLoading,
        },
      },
    },
    rejected: {
      title: `دلیل رد شدن!`,
      description: reason,
      button: {
        confirm: {
          label: 'بله ، متوجه شدم',
          onClick: () => handleQuestionModal(),
          loading: isLoading || RefetchLoading,
        },
        // reject: {
        //   label: 'خیر',
        //   onClick: () => handleQuestionModal(),
        // },
      },
    },
  };

  return (
    <Box
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'center',
        color: 'grey.800',
        ...sx,
      }}
      {...props}
    >
      {createPortal(
        <ModalLayout ref={ModalLayoutRemoveInputRef}>
          {showReason ? (
            <Reason isLoading={isLoading || RefetchLoading} onSubmit={handleSubmitReject} onClose={() => handleQuestionModal()} />
          ) : (
            <QuestionComponent
              {...{
                loading,
              }}
              onClose={() => handleQuestionModal()}
            >
              {history ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {' '}
                  <Box
                    sx={{
                      background: '#eee',
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ mb: 1 }}>
                      <Typography>{'ورودی قبلی کاربر'}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {type === formTypesObject.IMAGE.value ? (
                        <Image
                          sx={{
                            width: '100px',
                          }}
                          src={history.value}
                        />
                      ) : (
                        <Typography>{history.value}</Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: '#eee',
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ mb: 1 }}>
                      <Typography>{'ورودی جدید کاربر'}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {type === formTypesObject.IMAGE.value ? (
                        <Image
                          sx={{
                            width: '100px',
                          }}
                          src={props.value}
                        />
                      ) : (
                        <Typography>{history.value}</Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                ''
              )}
            </QuestionComponent>
          )}
        </ModalLayout>,
        document.body
      )}
      {confirm_status === 2 ? (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          <LoadingButton
            onClick={(e) => handleClick('reject', e)}
            sx={{ cursor: 'pointer' }}
            variant="outlined"
            color="success"
            size="small"
            loading={isLoading || RefetchLoading}
          >
            {'رد کردن'}
          </LoadingButton>
          <LoadingButton
            onClick={(e) => handleClick('accept', e)}
            sx={{ cursor: 'pointer' }}
            variant="contained"
            color="success"
            size="small"
            loading={isLoading || RefetchLoading}
          >
            {'تایید'}
          </LoadingButton>
        </Box>
      ) : confirm_status === 1 ? (
        <Stack direction={"row"} gap={1}>
          <LoadingButton
            sx={{ cursor: 'pointer', display: 'flex', gap: 1 }}
            variant="contained"
            color="success"
            size="small"
          >
            <DoneIcon fontSize="11px" />
            {'تایید شد'}
          </LoadingButton>
          <LoadingButton
            onClick={(e) => handleClick('reject', e)}
            sx={{ cursor: 'pointer' }}
            variant="outlined"
            color="success"
            size="small"
            loading={isLoading || RefetchLoading}
          >
            {'رد کردن'}
          </LoadingButton>
        </Stack>
      ) : confirm_status === 0 ? (
        <Tooltip title={reason} arrow>
          <LoadingButton
            onClick={(e) => handleClick('rejected', e)}
            sx={{ cursor: 'pointer', display: 'flex', gap: 1 }}
            variant="contained"
            color="error"
            size="small"
          >
            <RemoveRedEyeIcon fontSize="11px" />

            {'رد شد'}
          </LoadingButton>
        </Tooltip>
      ) : (
        ''
      )}
    </Box>
  );
};

export default AcceptOrRejectWithQuestionsWithReason;
