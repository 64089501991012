import { useFormContext } from 'react-hook-form';
import { useEffect, useState, useMemo } from 'react';
import useDebounce from '../../hooks/useDebounce';
import RHFSelector from '../hook-form/RHFSelector';
import api from '../../services/api';
import axiosInstance from '../../utils/axios';
import { useQueryCustom } from '../../utils/reactQueryHooks';
import RemoveNullObjectValue from '../../utils/RemoveNullObjectValue';
import { useSelector } from 'react-redux';

const convertor = {
  object: (x) =>
    x && {
      label: x?.name,
      // <div className="flex gap-3 items-center">
      // 	<img
      // 		className="rounded-full object-cover h-[45px] w-[45px] border border-primary-main"
      // 		src={import.meta.env.VITE_IMAGE_URL + x.image?.[0]?.location}
      // 	/>

      // 	<span>{x.name}</span>
      // </div>
      value: x?.id,
      data: x,
    },
  array: (x) => x?.map(convertor.object),
};

const Element = ({ queryParams, geById, ...props }) => {
  const { watch, setValue } = useFormContext();
  const watchData = watch(props.name);
  const validFetchById = !!(geById && !watchData?.value);
  const admin = useSelector((store) => store.admin.data);


  // ----------------------------------------------------------------

  const [apiParams, setApiParams] = useState({
    page: 1,
    perPage: 100,
    search: '',
  });

  const { debounce } = useDebounce({ setDebounce: (x) => setApiParams((p) => ({ ...p, search: x })) });
  // ----------------------------------------------------------------

  const getApi = async ({ queryKey }) => {
    const [_, params] = queryKey || [];
    if(admin?.province){
      return axiosInstance.get(`${api.province.base}`, { params: {...RemoveNullObjectValue(params) ,search:admin?.province?.name } });
    }
    return axiosInstance.get(`${api.province.base}`, { params: RemoveNullObjectValue(params) });
  };
  const resQuery = useQueryCustom({
    name: `${api.province.base}`,
    url: getApi,
    params: apiParams,
    // cacheTime: 300000,
    //staleTime: 300000,
  });
  const getByIdApi = async () => {
    return axiosInstance.get(`${api.province.byId}/${geById}`);
  };
  const resByIdQuery = useQueryCustom({
    name: `${api.province.byId}_${props.name}`,
    url: getByIdApi,
    params: apiParams,
    enabled: validFetchById,
    onSuccess: (resData) => {
      console.log('* * * ProvinceSelector getByIdApi :', { resData, geById });
      setValue(props.name, convertor.object(resData?.data));
    },
    //staleTime: 300000,
  });

  // ----------------------------------------------------------------

  const inputProps = {
    onChange: (e) => debounce(e.target.value),
  };

  const options = useMemo(() => {
    return convertor.array(resQuery?.data?.data?.data);
  }, [resQuery]);

  // ----------------------------------------------------------------
  // useEffect(() => {
  //   if (geById && !watchData?.value) getByIdApi();
  // }, [watchData, geById]);

  useEffect(() => {
    setApiParams((p) => ({ ...p, ...queryParams }));
  }, [queryParams]);

  useEffect(() => {
    if (!watchData && apiParams?.search) {
      setApiParams((p) => ({ ...p, search: '' }));
    }
  }, [watchData]);

  const defaultValue = validFetchById && convertor.object(resByIdQuery?.data?.data);
  // ----------------------------------------------------------------
  console.log('* * * ProvinceSelector onSuccess : ', {
    defaultValue,
    resQuery,
    options,
    watchData,
    validFetchById,
    resByIdQuery,
  });

  // ----------------------------------------------------------------
  return (
    <RHFSelector
      options={options}
      inputProps={inputProps}
      defaultValue={defaultValue}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      // loading={resQuery.isLoading}
      {...props}
    />
  );
};

const ProvinceSelector = {
  Element,
  convertor,
};

export default ProvinceSelector;
