import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormProvider } from "src/components/hook-form";
import validation from "./validation";
import persianToEnglishNumber from "src/utils/persianToEnglishNumber";
import { NATIONAL_CODE_REGEX } from "src/config";
import RHFTextareaField from "src/components/hook-form/RHFTextareaField";
import { LoadingButton } from "@mui/lab";

const JsonCodeMeliModal = ({
    closeModal = () => null,
    handleSubmitForm = () => null,
    loadingButton = false
}) => {

    const methods = useForm({
        resolver: yupResolver(validation.schema()),
    });

    const {
        reset,
        watch,
        control,
        setValue,
        setError,
        handleSubmit,
        getValues,
        formState: { errors, isSubmitting },
    } = methods;


    const onSubmit = async () => {
        const values = getValues();
        values[validation.fieldNames.code_melli] = persianToEnglishNumber(values[validation.fieldNames.code_melli]?.trim());
        const splitPhones = values[validation.fieldNames.code_melli]?.split('\n');
        // console.log('* * * onSubmit : ', { values, splitPhones });
        const inValidPhones = [];
        let isValid = true;
        splitPhones?.forEach((x) => {
            if (x?.trim()) {
                const valid = NATIONAL_CODE_REGEX.test(x);
                isValid = isValid && valid;
                if (!valid) inValidPhones.push(x);
            }
        });
        // console.log('* * * onSubmit : ', { isValid, inValidPhones });

        if (!isValid) {
            return setError(
                validation.fieldNames.code_melli,
                { message: `${inValidPhones?.join(' - ')} کدملی های نادرست ` },
                { shouldFocus: true }
            );
        }
        const reqData = {
            // [validation.fieldNames.message]: values[validation.fieldNames.message],
            [validation.fieldNames.code_melli]: splitPhones,
            // team_id: queryParams.id,
        };
        // const validation
        console.log('* * * onSubmit : ', { reqData, values, splitPhones, isValid, inValidPhones });
        handleSubmitForm(splitPhones)
        closeModal();
        // mutate(reqData);
    };
    return (
        <>
        <Box
          sx={{
            width: '100%',
            maxWidth: '550px',
            // display: { xs: 'block', md: 'flex' },
            gap: 2,
            bgcolor: 'background.paper',
            borderRadius: 1,
            px: 3,
            py: 3,
          }}
        >

            <Typography
                sx={{
                    fontWeight:"bold",
                    fontSize:18,
                    textAlign:"center",
                    mb:1
                }}
            >
                کدملی های مورد نظر را وارد کنید
            </Typography>

            <Typography
                color={"GrayText"}
                sx={{
                    fontWeight:"normal",
                    fontSize:14,
                    textAlign:"center",
                    mb:2
                }}
            >
                بعد از هر کدملی Enter بزنید
            </Typography>


            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <RHFTextareaField name={validation.fieldNames.code_melli} label={'کدملی'} />
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        mt: 3,
                        gap: 3,
                        justifyContent: 'center',
                    }}
                >
                    <Button onClick={closeModal}>
                        بستن
                    </Button>

                    <LoadingButton type="submit" variant="contained" color={'success'} loading={loadingButton}>
                        {'خروجی جیسون سازمان'}
                    </LoadingButton>
                </Box>
            </FormProvider>
        </Box>


        </>
    );
}

export default JsonCodeMeliModal;