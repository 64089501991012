import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Modal } from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../../../utils/reactQueryHooks';
import useQueryString from '../../../../../utils/useQueryString';
import Enum from '../enum';
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import useSettings from '../../../../../hooks/useSettings';
import Iconify from '../../../../../components/Iconify';
import TableComponent from '../../../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../../../hooks/useTable';
import { hasAccess } from '../../../../../permission/utiles';
import ShowCaravansManagementLayout from '../../Layout';
import buildAccess from 'src/permission/buildAccess';

const List = ({ access }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const queryString = useQueryString();

  const Notifaccess = buildAccess(PATH_DASHBOARD.notifications.name);
  const [ResendNotifId, setResendNotifId] = useState(-1)

  // ---------------------------------------------------------------------
  const getApi = async () =>
    axiosInstance.get(`${Enum?.api?.base}`, { params: { ...queryString, travel_id: queryParams.travel , team_id:queryParams.id } });

  const deleteApi = async ({ id }) => axiosInstance.delete(`${Enum?.api?.base}/${id}`);

  const resendNotifApi = async (data) => axiosInstance.post(`${Enum?.api?.send_again}`,data);

  // ---------------------------------------------------------------------

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams });
  // ------------------------------------------------------- query Get
  const { data: { data = {} } = {}, isLoading } = useQueryCustom({
    name: `${Enum?.api?.base}_list_${queryParams.id}`,
    url: getApi,
    params: { ...queryString },
  });
  // ------------------------------------------------------- query delete

  const deleteInput = useMutationCustom({
    url: deleteApi,
    name: `${Enum?.api?.base}_delete`,
    invalidQueries: [`${Enum?.api?.base}_list_${queryParams.id}`],
    onSuccess: () => {
      toast.success(t('question.delete.successfully'));
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });

  const resendNotif = useMutationCustom({
    url: resendNotifApi,
    name: `${Enum?.api?.base}_resend`,
    // invalidQueries: [`${Enum?.api?.base}_list`],
    onSuccess: (resData) => {
      setResendNotifId(-1);
      toast.success(resData?.data?.message);
    },
    onError: (error) => {
      console.log({ error });
      setResendNotifId(-1);
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });

  const checkAccess = useMemo(() => {
    return {
      // update: hasAccess(access?.update),
      delete: hasAccess(access?.delete),
      create: hasAccess(access?.create),
      confirm: hasAccess(access?.confirm),
    };
  }, [access]);
  const checkAccessNotif = useMemo(() => {
    return {
      update: hasAccess(Notifaccess?.update),
      delete: hasAccess(Notifaccess?.delete),
      create: hasAccess(Notifaccess?.create),
      confirm: hasAccess(Notifaccess?.confirm),
    };
  }, [Notifaccess]);

  const title = `مدیریت ${Enum.title.name[1]} `;
  const tableOptions = tableData({
    baseRoute: `${Enum.routes.root}`,
    onDelete: (x) => deleteInput.mutate({ id: x?.id }),
    resendNotif: (x) => {setResendNotifId(x?.id);resendNotif.mutate({ notification_id: x?.id })},
    resendLoading : ResendNotifId,
    travelId:queryParams?.travel,
    disabled: {
      update: !checkAccessNotif?.update,
      delete: !checkAccessNotif?.delete,
    },
  });

  console.log({ tableOptions, data });

  return (
    <ShowCaravansManagementLayout>
      <TableComponent
        active={{
          // selectable: true,
          rowNumber: true,
        }}
        options={tableOptions}
        data={data?.data}
        tableSetting={tableSetting}
        pagination={{
          totalPage: data?.meta?.last_page,
          total: data?.meta?.total,
        }}
        loading={isLoading}
      />{' '}
    </ShowCaravansManagementLayout>
  );
};

export default List;
