import { useEffect, useState, useMemo } from 'react';
import { fDate } from 'src/utils/formatTime';
import { useFormContext } from 'react-hook-form';
import RHFSelector from 'src/components/hook-form/RHFSelector';
import useDebounce from '../../hooks/useDebounce';
import api from '../../services/api';
import axiosInstance from '../../utils/axios';
import { useQueryCustom } from '../../utils/reactQueryHooks';
import RemoveNullObjectValue from '../../utils/RemoveNullObjectValue';

const convertor = {
  object: (x, disabledIds) =>
    x && {
      label: x?.dispatch_date ? `${x?.name || "بدون نام"} - ${fDate(x?.dispatch_date)}` : `${x?.name || "بدون نام"} - ${'زمان حرکت نامعلوم'}`,
      value: x?.id,
      data: x,
      disabled: (disabledIds?.length ? disabledIds?.includes(x?.id) : false),
    },
  array: (x, disabledIds) => x?.map?.((x) => convertor.object(x, disabledIds)),
};

const Element = ({ queryParams, ...props }) => {
  const { watch, setValue } = useFormContext();
  const watchData = watch(props.name);
  // const validFetchById = !!(geById && !watchData?.value);
  // ----------------------------------------------------------------

  const [apiParams, setApiParams] = useState({
    // page: 1,
    perPage: 100,
    search: '',
  });

  const { debounce } = useDebounce({ setDebounce: (x) => setApiParams((p) => ({ ...p, search: x })) });
  // ----------------------------------------------------------------

  const getApi = async ({ queryKey }) => {
    const [_, params] = queryKey || [];
    return axiosInstance.get(`${api.caravan.base}`, { params: RemoveNullObjectValue(params) });
  };

  const resQuery = useQueryCustom({
    name: `${api.caravan.base}`,
    url: getApi,
    params: apiParams,
    // enabled: !!provinceId,
  });
  // console.log('* * * CaravanSelector onSuccess : ', { resQuery });

  // ----------------------------------------------------------------

  const inputProps = {
    // onChange: (e) => debounce(e.target.value),
  };

  const options = useMemo(() => {
    return convertor.array(resQuery?.data?.data?.data);
  }, [resQuery]);

  // ----------------------------------------------------------------
  // useEffect(() => {
  //   if (geById && !watchData?.value) getByIdApi();
  // }, [watchData, geById]);
  useEffect(() => {
    setApiParams((p) => ({ ...p, ...queryParams }));
  }, [queryParams]);
  // ----------------------------------------------------------------
  console.log('* * * CaravanSelector onSuccess : ', { resQuery, options });

  // ----------------------------------------------------------------
  return <RHFSelector options={options} inputProps={inputProps} loading={resQuery.isLoading} {...props} />;
};

const CaravanSelector = {
  Element,
  convertor,
};

export default CaravanSelector;
