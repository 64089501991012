import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Modal, Typography } from '@mui/material';
import { agentsType } from 'src/enumeration';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import { useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ModalLayout from 'src/components/ModalLayout';
import QuestionComponent from 'src/components/ui/Question';
import { hasAccess } from 'src/permission/utiles';
import { LoadingButton } from '@mui/lab';
import api from 'src/services/api';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import useQueryString from '../../../utils/useQueryString';
import Enum from '../enum';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import TableComponent from '../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../hooks/useTable';
import { Download } from '@mui/icons-material';
import JsonCodeMeliModal from './codemeliModal';

const List = ({ access }) => {
  // const [AgentsInData, setAgentsInData] = useState();
  const { t } = useTranslation();
  const queryParams = useParams();
  const location = useLocation();
  const queryString = useQueryString();
  const ModalLayoutQuestionInputRef = useRef();
  const ModalLayoutQuestionJsonInputRef = useRef();
  const isRecord = queryParams.isRecord;
  const previous = location?.state;
  const [DownloadExcelLoading, setDownloadExcelLoading] = useState(false)
  const [DownloadJsonLoading, setDownloadJsonLoading] = useState(false)

  const checkAccess = useMemo(() => {
    return {
      update: hasAccess(access?.update),
      delete: hasAccess(access?.delete),
      create: hasAccess(access?.create),
      confirm: hasAccess(access?.confirm),
      read: hasAccess(access?.read),
    };
  }, [access]);

  const handleQuestionModal = () => ModalLayoutQuestionInputRef.current.show((p) => !p);
  const handleJsonCodeMeliModal = () => ModalLayoutQuestionJsonInputRef.current.show((p) => !p);

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams, checkAccess, access });
  // ------------------------------------------------------- query Get
  const getTravel = async () => axiosInstance.get(`${api.travel.base}/${queryParams.travel}`);

  const resTravel = useQueryCustom({
    name: `${api.travel.base}_get_${queryParams.travel}`,
    url: getTravel,
    params: { id: queryParams.travel },
    enabled: checkAccess?.read,
  });
  // ------------------------------------------------------- query lottery
  const onSuccessMutating = () => toast.success(t('successfully'));
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const postLottery = async (params) => axiosInstance.post(`${api?.lottery?.base}`, params);
  const lotteryMutation = useMutationCustom({
    url: postLottery,
    name: `${api?.lottery?.base}_post`,
    // invalidQuery: `${api?.lottery?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  const handleLottery = () => {
    // alert();
    lotteryMutation.mutate({
      travel_id: queryParams.travel,
    });
  };

  const deleteApi = async ({ id }) => axiosInstance.delete(`${Enum?.api?.base}/${id}`);

  // ------------------------------------------------------- query Get
  const getting = async () =>
    axiosInstance.get(`${Enum?.api?.base}`, { params: { ...queryString, travel_id: queryParams.travel } });
  const {
    data: { data: resData = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
    isFetching,
  } = useQueryCustom({
    name: `${Enum?.api?.base}_get_${queryParams.travel}`,
    url: getting,
    params: { ...queryString },
    // onSuccess: (x) => {
    //   console.log('* * * onSuccess :', { x });
    //   const agents = {};
    //   x?.data?.data?.forEach((x) => {
    //     x.agents?.forEach((y) => (y.agent ? (agents[y.agent.id] = y.agent) : true));
    //   });
    //   console.log('* * * onSuccess :', { agents });

    //   setAgentsInData(Object.values(agents));
    // },
  });

  const AgentsInData = useMemo(() => {
    const agents = {};
    resData?.data?.forEach((x) => {
      x.agents?.forEach((y) => (y.agent ? (agents[y.agent.id] = y.agent) : true));
    });
    console.log('* * * onSuccess :', { agents });

    return Object.values(agents);
  }, [resData]);

  const deleteInput = useMutationCustom({
    url: deleteApi,
    name: `${Enum?.api?.base}_delete`,
    invalidQueries: [`${Enum?.api?.base}_get_${queryParams.travel}`],
    onSuccess: () => {
      toast.success(t('question.delete.successfully'));
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });

  const handleDownloadExcel = async () => {
    const { filters, ...others } = queryString;
    const newFilters = [];
    // if (filters) newFilters = JSON.parse(filters || '')?.map((x) => ({ input_id: x.input_id, value: x.value }));
    // console.log('* * * getting : ', { newFilters , others});
    // delete newFilters?.page;
    // delete newFilters?.perPage;
    // delete others?.page;
    // delete others?.perPage;

    // const stateFilters = CustomFilterObj?.filters
    // for (const key in stateFilters) {
    //   if (Object.prototype.hasOwnProperty.call(stateFilters, key)) {
    //     const element = stateFilters[key];
    //     newFilters[key] = element?.value
    //   }
    // }

    // console.log('* * * getting : ', { newFilters, filters, stateFilters });

    // handleShowDownloadStausModal();

    try {
      setDownloadExcelLoading(true)
      const response = await axiosInstance.get(`${Enum?.api?.excel(queryParams.travel)}`, {
        responseType: 'blob',
        params: {
          // travel_id : queryParams.travel,
          // ...others,
          // ...(newFilters && (newFilters)),
          // ...(filters && { filters: JSON.stringify(newFilters) }),
        },
        // onDownloadProgress: (progressEvent) => {
        //   const { loaded, total } = progressEvent;

        //   // Calculate the percentage of the file downloaded
        //   // const progressPercentage = (loaded / total) * 100;
        //   const loadedInMB = (loaded / 1024 / 1024).toFixed(2); // Loaded size in MB

        //   setDownloadProgress(loadedInMB);

        //   // Set the total size of the file (only once)
        //   if (fileSize === 0 && total) {
        //     setFileSize(total); // Set total size in bytes
        //   }
        // },
      });

      console.log({ downloadExcelData: response?.data })

      const blob = new Blob([response.data]);

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'caravan.xlsx';
      link.click();

      // // Clean up and revoke the object URL after download
      window.URL.revokeObjectURL(link.href);
      // setTimeout(() => {
      //   navigate(`${PATH_DASHBOARD.report.excel.root}/${queryParams.travel}?show=true`);
      //   setDownloadExcelLoading(false)
      // }, 10000);

      // // await downloadFileFromAxios(data , "client");
      // handleShowDownloadStausModal();
      // setDownloadProgress(0)
      setDownloadExcelLoading(false)
    } catch (error) {
      console.log(error);
      // handleShowDownloadStausModal();
      // setDownloadProgress(0)
      setDownloadExcelLoading(false)
    }

  }

  const handleDownloadJson = async (codemeliArr) => {
 
    try {
      setDownloadJsonLoading(true)
      const response = await axiosInstance.get(`${Enum?.api?.hajJson}`, {
        responseType: 'blob',
        params: {
          // travel_id : queryParams.travel,
          code_melli : codemeliArr,
        },
      });

      console.log({ downloadExcelData: response?.data })

      const blob = new Blob([response.data]);

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'خروجی جیسون سازمان.json';
      link.click();

      // // Clean up and revoke the object URL after download
      window.URL.revokeObjectURL(link.href);
      setDownloadJsonLoading(false)
    } catch (error) {
      console.log(error);
      // handleShowDownloadStausModal();
      // setDownloadProgress(0)
      setDownloadJsonLoading(false)
    }

  }

  const title = `مدیریت ${Enum.title.name[1]} `;
  const tableOptions = tableData({
    baseRoute: isRecord
      ? `${PATH_DASHBOARD.records.caravansManagement(queryParams.type, true, queryParams.travel)}`
      : `${Enum.routes.root(queryParams.type, queryParams.travel)}`,
    AgentsInData,
    queryString,
    onDelete: (x) => deleteInput.mutate({ id: x?.id }),
    disabled: {
      update: !checkAccess?.update,
      delete: !checkAccess?.delete,
    },
  });

  const data = useMemo(() => {
    // const finalData = resData?.data?.map((x) => {
    //   // console.log('* * * useMemo', { x });
    //   x?.agents?.forEach((y) => {
    //     if (y?.agent?.id === agentsType.managerCaravan.value) x.manager = y;
    //     if (y?.agent?.id === agentsType.rohani.value) x.rohani = y;
    //     if (y?.agent?.id === agentsType.deputyCaravan.value) x.deputy = y;
    //     if (y?.agent?.id === agentsType.moeine.value) x.moeine = y;
    //     if (y?.agent?.id === agentsType.interface.value) x.interfaceId = y;
    //   });
    //   return x;
    //   // if (x?.id === agentsType.managerCaravan.value) x.manager = x;
    //   // if (x?.id === agentsType.rohani.value) x.rohani = x;
    //   // if (x?.id === agentsType.deputy.value) x.deputy = x;
    //   // if (x?.id === agentsType.moeine.value) x.moeine = x;
    //   // if (x?.id === agentsType.interface.value) x.interfaceId = x;
    // });

    return {
      ...resData,
      // data: finalData,
    };
  }, [resData]);

  console.log({ tableOptions, data, resData, queryParams });

  return (
    <Page title={`${title}`}>
      <ModalLayout ref={ModalLayoutQuestionInputRef}>
        <QuestionComponent
          {...{
            loading: lotteryMutation?.isLoading,
            title: 'آیا از قرعه کشی مطمئن هستید؟',
            description: 'پس از تایید قرعه کشی انجام خواهد شد . آیا ادامه میدهید؟',
            button: {
              confirm: {
                label: 'question.yesProcess',
                onClick: handleLottery,
              },
              reject: {
                label: 'question.no',
                onClick: handleQuestionModal,
              },
            },
          }}
        // onClose={handleQuestionModal}
        />
      </ModalLayout>

      {/* <ModalLayout ref={ModalLayoutQuestionInputRef}>
        <Lottery />
      </ModalLayout> */}
      <ModalLayout ref={ModalLayoutQuestionJsonInputRef}>
        <JsonCodeMeliModal 
          closeModal={handleJsonCodeMeliModal}
          handleSubmitForm={handleDownloadJson}
          loadingButton={DownloadJsonLoading}
        />
      </ModalLayout>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={isRecord ? resTravel?.data?.data?.name : title}
          back={
            previous?.pathname
              ? { pathname: previous?.pathname, search: new URLSearchParams(previous?.query || {}).toString() }
              : false
          }
          links={[
            { name: 'داشبورد', href: Enum.routes.root(queryParams.type, queryParams.travel) },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
          action={
            checkAccess?.create &&
            !isRecord && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <LoadingButton
                  // onClick={handleDownloadJson}
                  onClick={handleJsonCodeMeliModal}
                  variant="outlined"
                  color="success"
                  loading={DownloadJsonLoading}
                  // component={Link}
                  // to={baseUrl + Enum.routes.lottery}
                  endIcon={<Download />}
                >
                  {'خروجی جیسون سازمان'}
                </LoadingButton>
                <LoadingButton
                  onClick={handleDownloadExcel}
                  variant="outlined"
                  color="success"
                  loading={DownloadExcelLoading}
                  // component={Link}
                  // to={baseUrl + Enum.routes.lottery}
                  endIcon={<Download />}
                >
                  {'دریافت اکسل'}
                </LoadingButton>

                <Button
                  variant="contained"
                  color={'success'}
                  component={RouterLink}
                  to={`${Enum.routes.root(queryParams.type, queryParams.travel)}${Enum.routes.add}`}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                  {t('addAuthor', { author: Enum.title.name[0] })}
                </Button>
              </Box>
            )
          }
        >
          {isRecord ? <Typography sx={{ mt: 1 }}>{title}</Typography> : ''}
        </HeaderBreadcrumbs>
        <TableComponent
          active={{
            // selectable: true,
            rowNumber: true,
          }}
          options={tableOptions}
          data={data?.data}
          tableSetting={tableSetting}
          pagination={{
            totalPage: data?.meta?.last_page,
            total: data?.meta?.total,
          }}
          loading={isLoading}
        />{' '}
      </Container>
    </Page>
  );
};

export default List;
