import errorsText from 'src/utils/errorsText';
import * as yup from 'yup';

const validation = {
  // defaultValue:{},
  fieldNames: {
    code_melli: 'code_melli',
    // message: 'message',
  },
  schema: () =>
    yup.object().shape({
      // [validation.fieldNames.message]: yup.string().required(errorsText.blankError()),
      [validation.fieldNames.code_melli]: yup.string().required(errorsText.blankError()),
    }),
};
// "Passwords must match"
export default validation;
