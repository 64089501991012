import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateJalali } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';

export const tableData = ({ baseRoute, onDelete , disabled }) => [
  {
    key: 'name',
    header: 'نام',
    Component: ({ param }) => param?.name || '-',
  },

  {
    key: 'created_at',
    header: 'تاریخ ایجاد',
    Component: ({ param }) => fDate(param?.created_at) || '-',
  },
  {
    key: 'name',
    header: 'نوع دانشگاه',
    Component: ({ param }) => param?.university_category?.name || '-',
  },
  {
    key: 'iran_province',
    header: 'استان',
    Component: ({ param }) => param?.iran_province?.name || '-',
  },
  // {
  //   key: 'iran_county',
  //   header: 'شهر',
  //   Component: ({ param }) => param?.iran_county?.name || '-',
  // },
  {
    key: 'actions',
    header: '',
    Component: ({ param }) => (
      (!disabled?.update || !disabled?.delete) ? (
        <MoreTable>
          {!disabled?.update ? (
            <Link to={`${Enum.routes.root + Enum.routes.edit}/${param?.id}`}>
              <MoreTableItem isEdit />
            </Link>
          ) : (
            ''
          )}
          {!disabled?.delete ? <MoreTableItem isDelete onClick={() => onDelete(param)} /> : ''}
        </MoreTable>
      ) : (<></>)
    ),
    free: true,
  },
];
