import { useState, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Typography, Alert, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import SelectorComponent from 'src/components/hook-form/Selector';
import api from '../../../../../services/api';
import axiosInstance from '../../../../../utils/axios';
import Enum from '../../../enum';
import { useMutationCustom, useQueryCustom } from '../../../../../utils/reactQueryHooks';
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import useSettings from '../../../../../hooks/useSettings';
import WaitingBox from '../../../../../components/WaitingBox/index';
import validation from './validation';
import Layout from '../../Layout';
import UserStatusInfo from './UserStatusInfo';
import RegistrantForm from 'src/pages/RegistrantForms/components/Form';
import FirstRegistryForm from './staticForm';
import CaravansManagementShowNotification from '../../notifications';
import { hasAccess } from 'src/permission/utiles';
import buildAccess from 'src/permission/buildAccess';
import { CheckCircle } from '@mui/icons-material';
import ModalLayout from 'src/components/ModalLayout';
import { LoadingButton } from '@mui/lab';
import QuestionComponent from 'src/components/ui/Question/index';
import { PATH_DASHBOARD } from 'src/routes/paths';
import DutyRegisteryForm from './staticForm/duty';

const Travel = ({
  user
}) => {
  const access = buildAccess("Identity");
  const accessClient = buildAccess("Client");
  const accessStatus = buildAccess("Status");
  const accessMessage = buildAccess("Message");

  const ModalLayoutQuestionInputRef = useRef();
  const ModalLayoutSuccessRef = useRef();

  const handleQuestionModal = (params) => ModalLayoutQuestionInputRef.current.show((p) => !p, params);
  const handleSuccessModal = (params) => ModalLayoutSuccessRef.current?.show((p) => !p, params);


  const queryParams = useParams();

  // const { travel } = queryParams;
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState();
  const [searchParams] = useSearchParams();
  const backUrl = `${Enum.routes.root}`;
  const paramId = queryParams?.id;
  const currentBase = `${backUrl}/show/${paramId}/${Enum.enumTab.object.travels.value}`;
  // const ModalSectionFormRef = useRef();

  const navigate = useNavigate();

  // ------------------------------------------------------------------------------ modal toggle

  // ------------------------------------------------------------------------------ Mutation service
  // ----------------------------------------------------------------------------- SERVICE
  const getById = async () => axiosInstance.get(`${api.travelRegister.register.base}/${queryParams.course}`);
  const getByIdTravel = async () => axiosInstance.get(`${api.travel.base}/${searchParams.get("travel")}`);

  const resQuery = useQueryCustom({
    name: `${api.travelRegister.register.base}_get_${queryParams.course}`,
    url: getById,
    params: { id: queryParams.course, client_id: queryParams.id },
    staleTime: 0, // Data becomes stale immediately
    cacheTime: 0,
    enabled: hasAccess(access.read),
    onSuccess: (resData) => {
      const x = resData?.data?.form?.[0];
      if (x && !currentTab) setCurrentTab({ label: x?.name, value: x?.id, data: x });
      else {
        const formIndex = resData?.data?.form?.findIndex(item => item?.name === currentTab?.label);

        if (formIndex > -1) {
          setCurrentTab(prev => ({
            ...prev,
            data: resData?.data?.form?.[formIndex]
          }))
        }
      }
      // console.log({ resData , mainQuery : `${api.travelRegister.register.base}_get_${queryParams.course}`,currentTab , x ,con : (x && !currentTab) });
    },
  });

  const travelData = useQueryCustom({
    name: `${api.travel.base}_get_${searchParams.get("travel")}`,
    url: getByIdTravel,
    staleTime: 0, // Data becomes stale immediately
    cacheTime: 0,
    enabled: !hasAccess(access.read),
    onSuccess: (resData) => {
      const x = resData?.data?.forms?.[0];

      setCurrentTab({ label: x?.name, value: "firstForm", data: { ...x, sections: [{}, {}] } })

      // console.log({ resData , mainQuery : `${api.travelRegister.register.base}_get_${queryParams.course}`,currentTab , x ,con : (x && !currentTab) });
    },
  });

  const updating = (params) => axiosInstance.delete(`${api.client.base}/${queryParams.id}`);

  const onSuccessMutating = () => {
    handleQuestionModal()
    handleSuccessModal();
  };

  const { isLoading, mutate } = useMutationCustom({
    url: updating,
    name: `${api.client.base}_delete_client`,
    // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
    onSuccess: onSuccessMutating,
    //  onError: onErrorMutating,
  });


  // ------------------------------------------------------------------------------ submit form

  // // const creating = (params) => axiosInstance.post(api.travelRegister.base, params);
  // const updating = (params) =>
  //   axiosInstance.post(`${api.travelRegister.base}/${resQuery?.data?.data?.travel_register?.id}`, params);

  // const { isLoading, mutateAsync } = useMutationCustom({
  //   url: updating,
  //   name: `${api.public.travelRegister.base}_add`,
  //   // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
  //   onSuccess: onSuccessMutating,
  //   // onError: onErrorMutating,
  // });
  // ------------------------------------------------------------------------------ submit form

  // const sectionsQuery = useQueryCustom({
  //   name: `${api.section.base}_get_${id}`,
  //   url: getting,
  // });

  const tab = resQuery?.data?.data?.form?.map((x, index) => (index !== 0 ? (hasAccess(access.read) && ({ label: x?.name, value: x?.id, data: x })) : ({ label: x?.name, value: x?.id, data: x }))) || [];

  if (!hasAccess(access.read) && tab?.length === 0) {
    tab.push({
      label: 'ثبت نام اولیه',
      value: "firstForm",
      data: { sections: [{}, {}] }
    })
  }

  if (hasAccess(accessStatus.read)) {
    tab.push({
      label: 'وضعیت کاربر',
      value: 'userStatus',
    });
  }

  if (hasAccess(accessMessage.read)) {
    tab.push({ label: 'پیام ها', value: 'message' });
  }

  console.log({ currentTab, resQuery, tab, acc: hasAccess(access?.read) });

  return (
    <Layout>
      <ModalLayout ref={ModalLayoutQuestionInputRef}>
        <QuestionComponent
          {...{
            title: "آیا از حذف زائر مطمئن هستید؟",
            description: "پس از حذف زائر از لیست حذف میشود ، آیا ادامه میدهید",
          }}
          button={{
            confirm: {
              label: 'question.yesProcess',
              loading: isLoading,
              onClick: (_, data) => mutate(data),
            },
            reject: {
              label: 'question.no',
              onClick: () => handleQuestionModal(),
            },
          }}
        // onClose={() => handleQuestionModal()}
        />
      </ModalLayout>
      <ModalLayout ref={ModalLayoutSuccessRef} onClosed={() => null}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: 1,
            backgroundColor: 'common.white',
          }}
        >
          <Box
            sx={{
              mt: '-20px',
              svg: {
                width: '65px',
                height: '65px',
                color: 'success.main',
                backgroundColor: 'common.white',
                borderRadius: '100%',
              },
            }}
          >
            <CheckCircle />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 3,
              gap: 2,
              position: 'relative',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: 'success.main',
              }}
            >
              زائر باموفقیت حذف شد
            </Typography>

            {/* {true ? <Typography variant="h6">{'منتظر قرعه کشی باشید.'}</Typography> : ''} */}

            <Button
              color="success"
              variant="contained"
              onClick={() => {
                handleSuccessModal();
                navigate(PATH_DASHBOARD.profile.root)
                // completed?.();
                // refetch?.();
              }}
            >
              {'بستن'}
            </Button>
          </Box>
        </Box>
      </ModalLayout>
      <Box
        sx={
          {
            // py:3
          }
        }
      >
        {(resQuery.isLoading || travelData?.isLoading) ? (
          <WaitingBox />
        ) : resQuery.isError ? (
          ''
        ) : (
          <Page
            sx={{
              pb: 3,
            }}
          // title={resQuery?.data?.data?.name}
          >
            <HeaderBreadcrumbs heading={` ${user?.name || ''} ${user?.last_name || ''} ` + (queryParams.course ? `${resQuery?.data?.data?.form?.[0]?.travel ? `(${resQuery?.data?.data?.form?.[0]?.travel?.name || travelData?.data?.data?.forms?.[0]?.travel?.name})` : ""}` : "")}
              back={searchParams?.get("back") ? searchParams?.get("back") : `${currentBase}/`}
              action={
                <>
                  {hasAccess(accessClient.delete) &&
                    <>
                      <LoadingButton variant='contained' color='error' onClick={() => handleQuestionModal()}>
                        حذف زائر
                      </LoadingButton>
                    </>
                  }
                </>
              }
            />
            <Box
              sx={{
                // display: 'flex',
                alignItems: 'center',
                gap: 2,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {tab?.map((x) => {
                return (
                  // <Link to={`${currentBase}/${queryParams.course}?tab=${x.value}`}>
                  <Typography
                    onClick={() => setCurrentTab(x)}
                    sx={{
                      color: 'grey.800',
                      p: 1,
                      borderBottom: '2px solid transparent',
                      fontWeight: '300',
                      fontSize: '15px',
                      cursor: 'pointer',
                      ...(currentTab?.value == x.value
                        ? {
                          borderColor: 'grey.800',
                          fontWeight: '900',
                        }
                        : {
                          '&:hover': {
                            color: 'grey.600',
                          },
                        }),
                    }}
                  >
                    {x.label}
                  </Typography>
                  // </Link>
                );
              })}
              {/* <Typography
                    onClick={() => setCurrentTab(x)}
                    sx={{
                      color: 'grey.800',
                      p: 1,
                      borderBottom: '2px solid transparent',
                      fontWeight: '300',
                      fontSize: '15px',
                      cursor: 'pointer',
                      ...(+currentTab?.value === +x.value
                        ? {
                            borderColor: 'grey.800',
                            fontWeight: '900',
                          }
                        : {
                            '&:hover': {
                              color: 'grey.600',
                            },
                          }),
                    }}
                  >
                  {"وضعیت کاربر"}  
                  </Typography> */}
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                gap: 2,
                display: { md: 'none', xs: 'flex' },
              }}
            >
              <SelectorComponent
                sx={{
                  width: '200px',
                }}
                options={tab}
                onChange={(x) => {
                  console.log({ x });
                  setCurrentTab(x);
                  // navigate(`${currentBase}/${x.value}`);
                }}
                value={currentTab}
                isClearable={false}
              />
            </Box>
            <Box>
              {
                // resQuery.isFetching
                false ? (
                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <WaitingBox />
                  </Box>
                ) : currentTab?.value === 'userStatus' ? (
                  <UserStatusInfo data={resQuery?.data?.data?.travel_register} />
                ) : currentTab?.value === 'message' ? (
                  <>
                    <CaravansManagementShowNotification 
                      travelId={
                        resQuery?.data?.data?.travel_register?.travel?.id ||
                        travelData?.data?.data?.id
                      }
                    />
                  </>
                ) : currentTab?.data?.sections?.length ? (
                  // FirstRegistryForm
                  <>
                    {currentTab?.label === "ثبت نام اولیه" ?
                      <>
                        <FirstRegistryForm
                          travelId={
                            resQuery?.data?.data?.travel_register?.travel?.id ||
                            travelData?.data?.data?.id
                          }
                        />
                      </>

                      :
                      currentTab?.label === "اطلاعات نظام وظیفه" ?
                        <>
                          <DutyRegisteryForm
                            travelId={
                              resQuery?.data?.data?.travel_register?.travel?.id ||
                              travelData?.data?.data?.id
                            }
                            {...{
                              // travelId: resQuery?.data?.data?.travel_register?.id,
                              clientId: resQuery?.data?.data?.travel_register?.client?.id,
                              data: {
                                form: currentTab?.data,
                                team: resQuery?.data?.data?.team,
                                members: resQuery?.data?.data?.members,
                              },
                            }}
                          />
                        </>
                        :
                        <>

                          <RegistrantForm
                            isHistory
                            {...{
                              // isLoading,
                              // onSubmitting: mutateAsync,
                              data: {
                                form: currentTab?.data,
                                team: resQuery?.data?.data?.team,
                                members: resQuery?.data?.data?.members,
                              },
                              travelId: resQuery?.data?.data?.travel_register?.id,
                              clientId: resQuery?.data?.data?.travel_register?.client?.id,
                            }}
                          />
                        </>
                    }
                  </>
                ) : (
                  // <SingleForm {...{ data: { form: currentTab?.data, team: resQuery?.data?.data?.team } }} />
                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <Alert severity="info">{'موردی یافت نشد!'}</Alert>
                  </Box>
                )
              }
            </Box>
            {/* {resQuery?.data?.data?.form?.map((x, i) => (
              <Box>
                <SingleForm {...{ data: { form: x, team: resQuery?.data?.data?.team }, index: i }} />
              </Box>
            ))} */}
          </Page>
        )}
      </Box>
    </Layout>
  );
};

export default Travel;
